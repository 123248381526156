<template>
  <div class="w-100">
    <div class="edit-question-container p-3">
      <div class="row m-3">
        <form
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          autocomplete="off"
          class="form w-100 form-row align-items-center p-3"
          style="border-bottom: 1px solid rgb(208, 210, 211)"
          action="#"
          method="post"
          novalidate="novalidate"
          :id="'QuestionFormId_' + sectionId"
        >
          <div class="mr-1">Q{{ newQuestionIndex }}</div>
          <div v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Slider'" class="form-inline col-8">
            <div class="form-inline w-50">
              <label for="leftLabel" class="mr-1">Left Label</label>
              <input v-model="newMultiQuestionLabel[0]" :id="newQuestionLabel" :name="newQuestionLabel" type="text" class="form-control w-75 required" />
            </div>
            <div class="form-inline w-50">
              <label for="rightLabel" class="mr-1">Right Label</label>
              <input v-model="newMultiQuestionLabel[1]" :id="newQuestionLabel" :name="newQuestionLabel" type="text" class="form-control w-75 required" />
            </div>
          </div>
          <div v-else class="col-8">
            <input v-model="newQuestionLabel" :id="'newQuestionLabel_'+sectionId"  :name="newQuestionLabel" type="text" class="form-control required" style="width: 100%" />
          </div>
          <div class="col-3">
            <select :id="'widgetType_'+sectionId" v-model="newQuestionWidgetTypeId" name="widgetType" class="custom-select" @change="setSelectedWidgetType">
              <option value="">Select</option>
              <option
                v-for="(option, idxWidgetTypeOptionsNewQuestion) in this.widgetTypeOptions"
                v-bind:value="option.value"
                v-bind:key="idxWidgetTypeOptionsNewQuestion"
                v-bind:selected="newQuestionWidgetTypeId == option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <div v-if="displayPlaceholder" class="form-row w-100 mt-2">
            <div class="form-group col-12">
              <question-placeholder :initial-placeholder="widgetConfiguration.placeholder" @save="setWidgetConfigurationPlaceholder"> </question-placeholder>
            </div>
          </div>
          <div class="form-row w-100 mt-2">
            <div v-show="displayRequired" class="form-group col-lg-2 col-sm-12">
              <div class="form-group col-8">
                <label for="required" class="control-label pr-1">Required</label>
                <input type="checkbox" :id="'required_'+sectionId" name="required" v-model="newQuestionRequired" @click="checkQuestionRequired($event)" true-value="Y" false-value="N" />
              </div>
            </div>
            <div v-show="displayIncludeAsSegment" class="form-group col-lg-3 col-sm-12">
              <label for="segment" class="control-label pr-1">Include as segment in charts</label>
              <input type="checkbox" :id="'segment_'+sectionId" name="segment" v-model="newIncludeAsSegment" true-value="Y" false-value="N" @click="checkSegmentLimit($event)" />
            </div>
            <div v-show="newIncludeAsSegment == 'Y'" class="form-group col-lg-3 col-sm-12" style="margin-top: -0.3rem">
              <div class="form-inline">
                <label for="segmentLabel" class="control-label pr-1 text-danger">Label for chart</label>
                <input v-model="newSegmentLabel" :id="'segmentLabel_'+sectionId" name="segmentLabel" type="text" :class="newIncludeAsSegment == 'Y' ? 'form-control required' : 'form-control'" />
              </div>
            </div>
          </div>
        </form>
        <question-content
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Content Section'"
          :survey-name="$parent.surveyName"
          :initial-content-id="Number(widgetConfiguration.contentId)"
          :content-sections="$parent.surveyContentSections"
          @save="setWidgetConfigurationContentId"
          @reload="$emit('reload')"
        >
        </question-content>
        <div v-show="selectedWidgetType !== undefined && selectedWidgetType.name == 'Slider'" class="form-inline p-3 mt-2">
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Minimum Value</label>
            <input v-model="widgetConfiguration.min" :id="'minimumValue_'+sectionId" name="minimumValue" type="text" class="form-control w-50" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Maximum Value</label>
            <input v-model="widgetConfiguration.max" :id="'maximumValue_'+sectionId" name="maximumValue" type="text" class="form-control w-50" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Increment Value</label>
            <input v-model="widgetConfiguration.step" :id="'incrementValue_'+sectionId" name="incrementValue" type="text" class="form-control w-50" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Default Value</label>
            <input v-model="widgetConfiguration.value" :id="'defaultValue_'+sectionId" name="defaultValue" type="text" class="form-control w-50" />
          </div>
        </div>
        <question-option
          v-for="(widgetConfigurationOption, idxWidgetConfiguration) in this.widgetConfiguration.options"
          class="w-100 form-row"
          :key="widgetConfigurationOption.value"
          :new-question-option-index="idxWidgetConfiguration"
          :option-object="widgetConfigurationOption"
          @save="saveOption"
          @remove="removeOption"
        >
        </question-option>
        <div class="w-100 form-row">
          <modal
            :modal-id="'Revert Changes'"
            :modal-title="'Cancel Confirmation'"
            :modal-content="'Are you sure you want revert your changes ?'"
            :include-confirmation="true"
            @confirm="resetQuestion"
          />
          <div class="col p-3">
            <button class="btn btn-primary float-right ml-2" :disabled="disableCancelButton" @click="$modal.show('Revert Changes')">Cancel</button>
            <button type="submit" class="btn btn-primary float-right ml-2" :disabled="disableSaveButton" @click="addQuestion()">
              <span>Save Question</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jQuery from "jquery";
import $ from "jquery";

import QuestionOption from "../components/QuestionOption.vue";
import QuestionContent from "../components/QuestionContent.vue";
import QuestionPlaceholder from "../components/QuestionPlaceholder.vue";

import AdminService from "../services/admin.service";
import QuestionService from "../services/question.service";
import ContentMixins from "../mixins/ContentMixins.vue";

export default {
  name: "AddQuestion",
  mixins: [ContentMixins],
  components: {
    QuestionOption,
    QuestionContent,
    QuestionPlaceholder,
  },
  destroyed() {},
  computed: {
    displayPlaceholder: function () {
      if (typeof this.selectedWidgetType != "undefined") {
        return this.selectedWidgetType.name == "Textbox" || this.selectedWidgetType.name == "Phone Number" || this.selectedWidgetType.name == "Email Address";
      }
      return false;
    },
  },
  mounted() {
    this.setSelectedWidgetType();
    this.setupAddQuestionForm();
  },
  methods: {
    disableButtons: function () {
      this.disableSaveButton = true;
      this.disableCancelButton = true;
    },
    enableButtons: function () {
      this.disableSaveButton = false;
      this.disableCancelButton = false;
    },
    reloadContentSections: function () {
      this.$parent.$emit("reload");
    },
    setWidgetConfigurationContentId: function (result) {
      this.widgetConfiguration.contentId = result.contentId;
      this.newQuestionLabel = "Content - " + result.name;
      this.$parent.$emit("reload");
    },
    setWidgetConfigurationPlaceholder: function (result) {
      this.widgetConfiguration.placeholder = result;
    },
    checkQuestionRequired: function (e) {
      if (e.target.checked) {
        this.newQuestionRequired == "Y";
      } else {
        this.newQuestionRequired == "N";
      }
    },
    resetQuestion() {
      this.$parent.$data.mode = "view";
      this.newQuestionLabel = "";
      this.newQuestionRequired = "N";
      this.newIncludeAsSegment = "N";
      this.newSegmentLabel = "";
      this.newQuestionWidgetTypeId = "";

      this.setSelectedWidgetType();
    },
    saveOption: function (result) {
      var option = this.widgetConfiguration.options.find((x) => x.value == result.value);
      if (option !== undefined) {
        option.name = result.name;
      }
    },
    removeOption: function (result) {
      var option = this.widgetConfiguration.options.find((x) => x.value == result.value);
      if (option !== undefined) {
        this.widgetConfiguration.options.splice($.inArray(option, this.widgetConfiguration.options), 1);
      }
    },
    checkSegmentLimit(event) {
      if (event.target.checked) {
        if (1 + this.$parent.totalNumberOfChartSegments > this.$parent.maximumNumberOfChartSegments) {
          this.$notify(
            AdminService.displayErrorMessage({
              text: "The maximum number of chart segments have been selected. If you want to include any additional selections you must deselect an existing question.",
              duration: -1,
            })
          );
          event.preventDefault();
          this.newIncludeAsSegment = "N";
          event.target.checked = "off";
        } else {
          this.newIncludeAsSegment == "Y";
        }
      } else {
        this.newIncludeAsSegment == "N";
      }
    },
    async addQuestion() {
      if (!this.addQuestionForm.valid()) {
        return false;
      } else {
        if (
          this.newIncludeAsSegment == "Y" &&
          this.selectedWidgetType !== undefined &&
          this.selectedWidgetType.name != "Dropdown" &&
          this.selectedWidgetType.name != "Radio Button Group" &&
          this.selectedWidgetType.name != "Checkbox Group"
        ) {
          this.$notify(
            AdminService.displayErrorMessage({ text: "You must have a field type of dropdown, radio button, or checkbox if the question is marked as a segment.", duration: -1 })
          );
          return;
        }
        this.$root.setLoading(true);
        if (this.newMultiQuestionLabel.length > 0) {
          this.newQuestionLabel = this.newMultiQuestionLabel.toString();
        }
        this.$notify(AdminService.displayInfoMessage({ text: "Adding Question....Please wait" }));
        var result = {
          surveyId: this.surveyId,
          sectionId: this.sectionId,
          label: this.newQuestionLabel,
          required: this.newQuestionRequired,
          widgetTypeId: this.newQuestionWidgetTypeId,
          sequenceNumber: this.newQuestionIndex,
          includeAsSegment: this.newIncludeAsSegment,
          segmentLabel: this.newSegmentLabel,
          widgetConfiguration: JSON.stringify(this.widgetConfiguration),
        };
        if (!this.initialLoad) {
          QuestionService.addQuestion(result, this.addQuestionCallback, this);
        }
      }
    },
    addQuestionCallback(response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        this.$parent.$data.questions.push(response.data.data);
        this.$parent.$data.mode = "view";
        this.$parent.setTotalNumberOfChartSegments();
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    setSelectedWidgetType() {
      this.selectedWidgetType = this.getWidgetType(this.newQuestionWidgetTypeId);
      if (!jQuery.isEmptyObject(this.selectedWidgetType)) {
        if (this.selectedWidgetType.name == "Dropdown") {
          this.widgetConfiguration.options = [
            { name: "", value: "-1" },
            { name: "PLEASE SELECT", value: "0", selected: "selected" },
            { name: " ", value: "1" },
          ];
        } else if (this.selectedWidgetType.name == "Checkbox Group" || this.selectedWidgetType.name == "Radio Button Group") {
          this.widgetConfiguration.options = [{ name: " ", value: "1" }];
        } else {
          this.widgetConfiguration.options = [];
        }
        if (this.selectedWidgetType.name == "Slider") {
          if (jQuery.isEmptyObject(this.Configuration)) {
            this.widgetConfiguration = { min: 6, max: 605, step: 100, value: 308 };
          }
        }
      }
    },
    getWidgetType(widgetId) {
      if (this.widgetTypeOptions) {
        return this.widgetTypeOptions.find((x) => x.value == widgetId);
      } else {
        return {};
      }
    },
    setupAddQuestionForm() {
      this.addQuestionForm = $("#QuestionFormId_" + this.sectionId);
      jQuery.validator.setDefaults({
        debug: true,
        success: "valid",
      });
      jQuery.validator.addMethod(
        "notEqual",
        function (value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );
      this.validator = this.addQuestionForm.validate({
        rules: {
          widgetType: {
            required: true,
            notEqual: "0",
          },
        },
        highlight: function (element) {
          $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
        },
        unhighlight: function (element) {
          $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function (error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        },
      });
    },
  },
  watch: {},
  data() {
    return {
      disableSaveButton: false,
      disableCancelButton: false,
      addQuestionForm: null,
      validator: null,
      newQuestionLabel: "",
      newQuestionRequired: "N",
      newIncludeAsSegment: "N",
      newSegmentLabel: "",
      newQuestionWidgetTypeId: "",
      selectedWidgetType: {},
      newMultiQuestionLabel: [],
      widgetConfiguration: {
        options: [],
      },
    };
  },
  props: {
    newQuestionIndex: {
      type: Number,
      default: 0,
    },
    widgetTypeOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    surveyId: {
      type: Number,
      default: 0,
    },
    sectionId: {
      type: Number,
      default: 0,
    },
    displayRequired: {
      type: Boolean,
      default: false,
    },
    displayIncludeAsSegment: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
