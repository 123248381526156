<template>
  <div></div>
</template>
<script>
import Vue from "vue";
import jQuery from "jquery";
import $ from "jquery";
import axios from "axios";
import AdminService from "../services/admin.service";

export default {
  name: "SectionMixins",
  data() {
    return {
      questions: [],
    };
  },
  props: {
    sectionObject: {
      type: Object,
      default: function () {
        return {};
      },
    },
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl(),
    },
  },
  computed: {
    section: function () {
      return this.sectionObject;
    },
    sectionId: function () {
      return this.sectionObject.sectionId;
    },
    sectionTypeName: {
      get: function () {
        return this.sectionObject.sectionType.name;
      },
      set: function (newValue) {
        this.sectionObject.sectionType.name = newValue;
      },
    },
    sectionHeader: {
      get: function () {
        return this.sectionObject.header;
      },
      set: function (newValue) {
        this.sectionObject.header = newValue;
      },
    },
    sectionDescription: {
      get: function () {
        return this.sectionObject.description;
      },
      set: function (newValue) {
        this.sectionObject.description = newValue;
      },
    },
    sectionName: {
      get: function () {
        return this.sectionObject.name;
      },
      // setter
      set: function (newValue) {
        this.sectionObject.name = newValue;
      },
    },
    randomizeFieldName: function () {
      let field;
      if (this.sectionName.includes("Actual Emotional Needs")) {
        field = "actual-randomize-emotional-questions";
      } else if (this.sectionName.includes("Actual Functional Needs")) {
        field = "actual-randomize-functional-questions";
      } else if (this.sectionName.includes("Ideal Emotional Needs")) {
        field = "ideal-randomize-emotional-questions";
      } else if (this.sectionName.includes("Ideal Functional Needs")) {
        field = "ideal-randomize-functional-questions";
      } else if (this.sectionName.includes("Competitor Emotional Needs")) {
        field = "competitor-randomize-emotional-questions";
      } else if (this.sectionName.includes("Competitor Functional Needs")) {
        field = "competitor-randomize-functional-questions";
      } else {
        field = "";
      }
      return field;
    },
  },
  methods: {},
  created() {
    this.questions = this.sectionObject.questions;
  },
  watch: {
    sectionObject: function () {
      this.questions = this.sectionObject.questions;
    },
  },
  mounted() {},
};
</script>
