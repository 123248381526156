import axios from "axios";

const API_URL = process.env.VUE_APP_BASE_URL + "/api/auth/";

class AuthService {
  sendForgotPasswordEmail(params, callback) {
    return axios.post(API_URL + "send-forgot-password", params).then((response) => {
      callback(response);
    });
  }
  resetPassword(password, token, callback) {
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("password", password);

    return axios.post(API_URL + "set-new-password", params).then((response) => {
      callback(response);
    });
  }
  verifyToken(token, callback) {
    const params = new URLSearchParams();
    params.append("token", token);

    return axios.post(API_URL + "verify-token", params).then((response) => {
      callback(response);
    });
  }
  login(user) {
    return axios
      .post(API_URL + "signin", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("client", JSON.stringify(response.data.client));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.clear();
  }
  hasSessionExpired(loggedIn) {
    let hasSessionExpired = true;
    let ttl = 1800000; // 30 minutes
    let currentUser = JSON.parse(loggedIn);
    if (currentUser && currentUser.lastActivityDate) {
      let loginTime = new Date(currentUser.lastActivityDate).getTime();
      loginTime += ttl;

      let currentTime = new Date().getTime();
      if (currentTime < loginTime) {
        hasSessionExpired = false;
        // extend the time. This will reset the 30 minutes of inactivity
        currentUser.lastActivityDate = new Date();
        localStorage.setItem("user", JSON.stringify(currentUser));
      }
    }

    return hasSessionExpired;
  }
}

export default new AuthService();
