<template>
  <div v-if="isAdmin || isSurveyDesigner || isManageInvitations" class="panel-admin-body add-content">
    <div v-if="isAdmin" class="title">{{ title }}</div>
    <div v-if="getDisplayView()" class="edit-content">
      <div v-if="isAdmin" class="p-2">
        <router-link :to="{ name: 'content-list' }">
          <button type="button" class="btn btn-success" id="ReturnToContentListButton">Return to Content List</button>
        </router-link>
      </div>
      <div v-show="displayPageField" class="form-group">
        <label for="summaryText" class="">Page</label>
        <input v-model="formContentObject.page" id="page" name="page" class="form-control col-12" type="text" :disabled="isEditMode" />
      </div>
      <div v-show="displayTitleField" class="form-group">
        <label for="contentTitle" class="">Title</label>
        <input v-model="formContentObject.title" id="contentTitle" name="contentTitle" class="form-control col-12" type="text" />
      </div>
      <div v-show="displaySummaryField" class="form-group">
        <label for="summaryText" class="">Summary Text</label>
        <input v-model="formContentObject.summaryText" id="summaryText" name="summaryText" class="form-control col-12" type="text" />
      </div>
      <div class="form-group">
        <label v-show="displayTitleField" for="summaryText" class="">Content Text</label>
        <textarea id="contentText" name="contentText" class="form-control w-100"> </textarea>
      </div>
      <div class="mt-2 row" role="group">
        <button type="submit" class="btn btn-primary float-right ml-2" @click="saveContent()">
          <span>Save</span>
          <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
        <button type="submit" class="btn btn-primary float-right ml-2" @click="closeContent()">
          <span>Cancel</span>
          <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="panel-admin-body content-email-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";
import "summernote/dist/summernote.css";
import "bootstrap";
import "summernote";

import authHeader from "../../services/auth-header";
import AdminService from "../../services/admin.service";
import ErrorService from "../../services/error.service";
import UserService from "../../services/user.service";
import ContentService from "../../services/content.service";

require("jquery-validation");

import AdminMixins from "../../mixins/AdminMixins.vue";

export default {
  name: "EditContent",
  mixins: [AdminMixins],
  components: {},
  data() {
    return {
      mode: "",
      title: "Add New Content",
      formContentObject: {
        contentId: 0,
      },
      summernoteConfiguration: ContentService.getContentConfiguration(),
    };
  },
  methods: {
    async getContent() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Retrieving content please stand by......" }));
      ContentService.getContentById("", this.initialContentId, this.getContentCallback, this);
    },
    getContentCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.formContentObject = response.data.data;
        this.contentId = this.formContentObject.contentId;
        this.setDisplayView(true);
        let vm = this;
        $(document).ready(function () {
          $("#contentText").summernote(vm.summernoteConfiguration);
          $("#contentText").summernote("code", vm.formContentObject.contentText);
        });
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async saveContent() {
      let content = $("#contentText").summernote("code");
      this.formContentObject.contentText = content;
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Saving content please stand by......" }));
      const params = new URLSearchParams();
      params.append("contentObject", JSON.stringify(this.formContentObject));
      params.append("userId", UserService.getUserId());
      await axios
        .post(this.baseUrl + "/api/v1/content/save-content", params, { headers: authHeader() })
        .then((response) => this.saveContentCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("EditContent.AddContent", error, "admin", this);
        });
    },
    saveContentCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        this.formContentObject = response.data.data;
        this.contentId = this.formContentObject.contentId;
        if (this.isSurveyDesigner) {
          let content = {
            contentId: this.formContentObject.contentId,
            name: this.formContentObject.title,
          };
          this.$emit("save", content);
          if (!this.isManageInvitations) {
            this.formContentObject.title = "";
            this.formContentObject.summaryText = "";
            $("#contentText").summernote("code", "");
          }
        }
        this.mode = "edit";
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    closeContent() {
      if (this.isSurveyDesigner || this.isManageInvitations) {
        this.$emit("close");
      } else {
        this.$router.replace({ name: "content-list" });
      }
    },
  },
  computed: {
    isEditMode: function () {
      return this.mode == "edit";
    },
    isSurveyDesigner: function () {
      return this.source == "survey-designer";
    },
    isManageInvitations: function () {
      return this.source == "manage-invitations";
    },
  },
  created() {
    this.mode = this.initialMode;
    if (this.mode == "edit") {
      this.getContent();
      this.title = "Modify Content";
    } else {
      this.setDisplayView(true);
      let vm = this;
      $(document).ready(function () {
        $("#contentText").summernote(vm.summernoteConfiguration);
      });
    }
    if (this.initialPage != "") {
      this.formContentObject.page = this.initialPage;
    }
    if (this.initialTitle != "") {
      this.formContentObject.title = this.initialTitle;
    }
    if (this.initialSummaryText != "") {
      this.formContentObject.summaryText = this.initialSummaryText;
    }
  },
  props: {
    initialMode: {
      type: String,
      default: "add",
    },
    initialPage: {
      type: String,
      default: "",
    },
    initialTitle: {
      type: String,
      default: "",
    },
    initialSummaryText: {
      type: String,
      default: "",
    },
    initialContentId: {
      type: Number,
      default: 0,
    },
    source: {
      type: String,
      default: "admin",
    },
    displayPageField: {
      type: Boolean,
      default: true,
    },
    displayTitleField: {
      type: Boolean,
      default: true,
    },
    disableSummaryField: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
};
</script>
