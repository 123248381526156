<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container response-message-list">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <div v-else class="panel-admin-body npa-email-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";

export default {
  name: "ResponseMessageList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "Response Message List",
      table: {
        id: "response-message-list-table",
        columns: [
          {
            title: "Id",
            data: "responseMessageLogId",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "2rem",
          },
          { title: "Title", data: "title", name: "title", width: "5rem" },
          { title: "Message", data: "message", name: "message", width: "10rem" },
          {
            title: "Completed",
            data: "completed",
            name: "completed",
            width: "3rem",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
          },
          {
            title: "Status",
            data: "statusCode",
            name: "statusCode",
            width: "2rem",
            createdCell: function (td, cellData, rowData, row, col) {
              if (cellData != "200") {
                $(td).css("background-color", "red");
                $(td).css("color", "white");
              } else if (cellData == "200") {
                $(td).css("background-color", "green");
                $(td).css("color", "white");
              }
              $(td).addClass("text-center");
            },
          },
          {
            title: "Duration (ms)",
            data: "duration",
            name: "duration",
            width: "2rem",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
          },
          { title: "Request Path", data: "requestPath", name: "requestPath", width: "10rem" },
          { title: "Request Parameters", data: "requestParameters", name: "requestParameters", width: "20rem" },
          { title: "User Id", data: "userId", name: "userId", width: "2rem" },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            width: "7rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY hh:mm:ss A");
            },
          },
          {
            title: "Date Completed",
            data: "dateCompleted",
            type: "date",
            name: "dateCompleted",
            width: "7rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY hh:mm:ss A");
            },
          },
        ],
      },
    };
  },
  methods: {
    async getTableData() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Loading table data please wait....." }));
      await axios
        .get(this.baseUrl + "/api/v1/admin/get-response-message-list", { headers: authHeader() })
        .then((response) => this.getTableDataCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ResponseMessageList.getTableData", error, "admin", this);
        });
    },
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[9, "desc"]];
    }
  },
  props: {},
  mounted() {},
};
</script>
