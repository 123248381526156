<template>
  <survey-taker :entry-client-id="entryClientId" :entry-survey-url="entrySurveyUrl" type="form"></survey-taker>  
</template>
<script>
import Vue from "vue";
import VueLogger from "vuejs-logger";

import SurveyTaker from "./views/SurveyTaker.vue";

const options = {
  isEnabled: true,
  logLevel: typeof process.env.VUE_APP_LOG_LEVEL != "undefined" ? process.env.VUE_APP_LOG_LEVEL : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);

export default {
  name: "FormTakerApp",
  components: {SurveyTaker}, 
  mounted() {},
  data() {
    return {
      entryClientId: process.env.VUE_APP_DEFAULT_FORM_CLIENT_ID,
      entrySurveyUrl: process.env.VUE_APP_DEFAULT_FORM_URL
    };
  },  
};
</script>
