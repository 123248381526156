<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons/faCubes";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faFighterJet } from "@fortawesome/free-solid-svg-icons/faFighterJet";
import { faFortAwesome } from "@fortawesome/free-brands-svg-icons/faFortAwesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faPaintBrush } from "@fortawesome/free-solid-svg-icons/faPaintBrush";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons/faAddressCard";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons/faArrowsAltH";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowCircleRight";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faFileImport } from "@fortawesome/free-solid-svg-icons/faFileImport";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import { faGem } from "@fortawesome/free-solid-svg-icons/faGem";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons/faBalanceScale";
import { faSortNumericDownAlt } from "@fortawesome/free-solid-svg-icons/faSortNumericDownAlt";
import { faSortNumericUpAlt } from "@fortawesome/free-solid-svg-icons/faSortNumericUpAlt";
import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons/faSortAmountDownAlt";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";

import { faUserSecret } from "@fortawesome/free-solid-svg-icons/faUserSecret";
import { faIdCard } from "@fortawesome/free-solid-svg-icons/faIdCard";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faUniversity } from "@fortawesome/free-solid-svg-icons/faUniversity";
import { faThList } from "@fortawesome/free-solid-svg-icons/faThList";
import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons/faPuzzlePiece";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons/faFlagCheckered";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faRandom } from "@fortawesome/free-solid-svg-icons/faRandom";
import { faTachometer } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons/faGripVertical";
//import { faFilesO } from "@fortawesome/free-solid-svg-icons/faFilesO";

import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { faBullseye } from "@fortawesome/free-solid-svg-icons/faBullseye";
import { faSitemap } from "@fortawesome/free-solid-svg-icons/faSitemap";
import { faThermometerThreeQuarters } from "@fortawesome/free-solid-svg-icons/faThermometerThreeQuarters";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faStreetView } from "@fortawesome/free-solid-svg-icons/faStreetView";
import { faChess } from "@fortawesome/free-solid-svg-icons/faChess";

library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faArrowCircleRight);
library.add(faFortAwesome);
library.add(faCubes);
library.add(faChartBar);
library.add(faChartLine);
library.add(faFighterJet);
library.add(faBars);
library.add(faAngleRight);
library.add(faBuilding);
library.add(faDollarSign);
library.add(faClock);
library.add(faPaintBrush);
library.add(faAddressCard);
library.add(faArrowsAltH);
library.add(faSync);
library.add(faWindowClose);
library.add(faClipboardList);
library.add(faPlus);
library.add(faEdit);
library.add(faSpinner);
library.add(faBan);
library.add(faEye);
library.add(faImage);
library.add(faFileImport);
library.add(faSave);
library.add(faSignOutAlt);
library.add(faUser);
library.add(faSyncAlt);
library.add(faGem);
library.add(faCopy);
library.add(faHandHoldingUsd);
library.add(faUsers);
library.add(faSort);
library.add(faBalanceScale);
library.add(faSortNumericDownAlt);
library.add(faSortNumericUpAlt);
library.add(faDownload);
library.add(faUpload);

library.add(faSortAmountDownAlt);
library.add(faTachometerAlt);
library.add(faUserSecret);
library.add(faIdCard);
library.add(faUserPlus);
library.add(faUniversity);
library.add(faThList);
library.add(faCogs);
library.add(faEnvelope);
library.add(faTasks);
library.add(faKey);
library.add(faPuzzlePiece);
library.add(faFlagCheckered);
library.add(faWrench);
library.add(faDatabase);
library.add(faRandom);
library.add(faTachometerAlt);
library.add(faGripVertical);
//library.add(faFilesO);
library.add(faMoneyBill);
library.add(faBullseye);
library.add(faSitemap);
library.add(faThermometerThreeQuarters);
library.add(faChartPie);
library.add(faLightbulb);
library.add(faStreetView);
library.add(faChess);

Vue.component("font-awesome-icon", FontAwesomeIcon);

export default {
  name: "MenuMixins",
  components: {
    /* eslint-disable vue/no-unused-components */
    FontAwesomeIcon,
  },
  data() {
    return {
      index: 0,
      activeClass: "flex-fill md-step active",
      notActiveClass: "flex-fill md-step",
    };
  },
  props: {},
  created() {},
  methods: {
    isActivePage: function (inputIndex) {
      return this.$parent.pageNumber == inputIndex;
    },
  },
  watch: {},
  mounted() {},
  computed: {
    showMenu: function () {
      return this.$root.showMenu;
    },
  },
};
</script>
