<template>
  <div class="container">
    <notifications group="auth" position="top center" />
    <div class="form-group form-row p-2">
      <h5 class="font-weight-bold">Forgot Password</h5>
    </div>
    <form id="ForgotPasswordFormId" name="form" @submit.prevent="sendForgotPasswordEmail">
      <div class="container">
        <div class="form-group form-row align-items-center">Please enter your email address and we will send you your password</div>
        <div class="form-group form-inline no-gutters">
          <label for="email" class="col-2">Username</label>
          <input v-model="username" id="username" name="username" class="form-control col-10 required" type="text" />
        </div>
        <div class="form-group form-inline no-gutters">
          <label for="email" class="col-2">Email</label>
          <input v-model="email" id="email" name="email" class="form-control col-10 required" type="text" />
        </div>
      </div>
      <div role="group" class="chart-buttons btn-group-horizontal">
        <button class="btn btn-primary btn-block" :disabled="this.$root.getLoading()">
          <span>Send</span>
          <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm ml-2"></span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";

import AuthService from "../services/auth.service";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      username: "",
      validator: null,
      forgotPasswordForm: null,
    };
  },
  methods: {
    sendForgotPasswordEmailCallback(response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      this.displayResponse = true;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message, group: "auth" }));
        this.$modal.hide("forgot-password");
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async sendForgotPasswordEmail() {
      if (!this.forgotPasswordForm.valid()) {
        return false;
      } else {
        this.$root.setLoading(true);
        const params = new URLSearchParams();
        params.append("email", this.email);
        params.append("username", this.username);
        await AuthService.sendForgotPasswordEmail(params, this.sendForgotPasswordEmailCallback).catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ForgetPassword.sendForgotPasswordEmail", error, "admin", this);
          this.$notify(AdminService.displayErrorMessage({ text: error.message, group: "auth" }));
        });
      }
    },
    setupForgotPasswordForm() {
      this.forgotPasswordForm = $("#ForgotPasswordFormId");
      jQuery.validator.setDefaults({
        debug: true,
        success: "valid",
      });
      jQuery.validator.addMethod(
        "notEqual",
        function (value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );
      this.validator = this.forgotPasswordForm.validate({
        rules: {},
        highlight: function (element) {
          $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
        },
        unhighlight: function (element) {
          $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function (error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        },
      });
    },
  },
  destroyed() {},
  computed: {},
  mounted() {
    this.setupForgotPasswordForm();
  },
  watch: {},
  props: {},
};
</script>
