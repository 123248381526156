<template>
  <router-view />
</template>
<script>
import Vue from "vue";
import VueLogger from "vuejs-logger";

const options = {
  isEnabled: true,
  logLevel: typeof process.env.VUE_APP_LOG_LEVEL != "undefined" ? process.env.VUE_APP_LOG_LEVEL : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);

export default {
  name: "InterviewApp",
  components: {},
};
</script>
