<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import RespondentMenu from "../views/menu/RespondentMenu.vue";
import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";

import authHeader from "../services/auth-header";
import ClientService from "../services/client.service";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import UserService from "../services/user.service";

export default {
  name: "RespondentMixins",
  mixins: [ContentMixins, ViewMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    RespondentMenu,
  },
  data() {
    return {
      disableButton: false,
      survey: {},
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl(),
    },
  },
  created() {},
  methods: {
    setupSurveyDesignerCallBack(response) {
      this.$notify({ group: "admin", clean: true });
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data.survey;
        if (typeof this.competitors != undefined) {
          this.competitors = response.data.data.competitors;
        }
        if (typeof this.numberOfCompletedSurveys != undefined) {
          this.numberOfCompletedSurveys = response.data.data.numberOfCompletedSurveys;
        }
        this.postLoadData();
      } else {
        this.goToCreateSurvey();
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async setupSurveyDesigner() {
      this.resetData();
      this.$root.setLoading(true);
      this.$notify({ group: "admin", clean: true });
      this.$notify(AdminService.displayInfoMessage({ text: "Loading survey setup please wait....." }));
      const params = new URLSearchParams();
      params.append("surveyCode", this.surveyCode);
      params.append("userId", UserService.getUserId());

      await axios
        .post(this.baseUrl + "/api/v1/survey-design/get-existing-survey-design", params, { headers: authHeader() })
        .then((response) => this.setupSurveyDesignerCallBack(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("RespondentMixins.setupSurveyDesigner", error, "survey-designer", this);
        });
    },
    saveSurveyField: function (result) {
      var match = this.surveyFields.find((x) => Number(x.surveyFieldId) === Number(result.surveyFieldId));
      if (typeof match !== "undefined") {
        this.surveyFields.splice($.inArray(match, this.surveyFields), 1);
      }
      this.surveyFields.push(result);
      if (result.buildSurvey) {
        this.saveBuildRequired("Y");
      }
    },
    saveSurveyFieldWithSuccessMessage: function (result) {
      this.saveSurveyField(result);
      this.displayDefaultSuccessMessage();
    },
    getSurveyField(surveyFieldName) {
      if (this.surveyFields) {
        return this.surveyFields.find((x) => x.surveyFieldName == surveyFieldName);
      } else {
        return {};
      }
    },
    goToCreateSurvey: function () {
      if (this.$router.currentRoute.name != "create-survey") {
        this.$router.replace({ path: "/design/create-survey" });
      }
    },
    goToDownload: function () {
      if (this.$router.currentRoute.name != "respondent-download") {
        this.$router.replace({ path: "/respondent/download" });
      }
    },
    goToTheCompetitors: function () {
      if (this.$router.currentRoute.name != "ideal") {
        this.$router.replace({ path: "/design/ideal" });
      }
    },
    goToInvitations: function () {
      if (this.hasSurveyBeenBuilt || this.isSurveyEmpty) {
        if (this.$router.currentRoute.name != "manage-invitations") {
          this.$router.replace({ path: "/respondent/invitations" });
        }
      } else {
        this.$notify(AdminService.displayInfoMessage({ text: "You are being redirected to the Constants page. Please click the Next button to generate the survey" }));
        let vm = this;
        setTimeout(function () {
          vm.goToTheCompetitors();
        }, 1000);
      }
    },
    resetData() {
      this.setDisplayView(false);
    },
    loadData: function () {
      this.setupSurveyDesigner();
    },
  },
  watch: {
    surveyCode: function () {
      this.loadData();
    },
    displaySurveyWidget: function () {
      return this.$parent.displaySurveyWidget;
    },
  },
  mounted() {
    if (this.clientId != 0) {
      this.loadData();
    }
    this.$parent.displayShowMenuIcon = true;
  },
  computed: {
    clientId: function () {
      if (typeof this.$parent.$data.clientId != "undefined") {
        return this.$parent.$data.clientId;
      } else {
        return 0;
      }
    },
    clientName: function () {
      if (typeof this.$parent.clientObject.name != "undefined") {
        return this.$parent.clientObject.name;
      } else {
        return "";
      }
    },
    surveyCode: function () {
      return this.$parent.$data.surveyCode;
    },
    surveyFields: function () {
      if (this.survey.surveyFields) {
        return this.survey.surveyFields;
      } else {
        return [];
      }
    },
    sections: function () {
      if (this.survey.sections) {
        return this.survey.sections;
      } else {
        return [];
      }
    },
    isSurveyEmpty: function () {
      return jQuery.isEmptyObject(this.survey);
    },
    hasSurveyBeenBuilt: function () {
      return this.survey.buildRequired == "N";
    },
    isAdvancedUser: function () {
      return this.$parent.isAdvancedUser || this.$parent.isAdmin;
    },
    buttonText: function () {
      return this.survey.buildRequired == "N" ? "Save" : "Next";
    },
  },
};
</script>
