<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
var dt = require("datatables.net");
require("datatables.net-bs4");
require("jszip");
require("datatables.net-buttons/js/buttons.html5.js");

import "bootstrap";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";

export default {
  name: "DataTableMixins",
  data() {
    return {
      table: {
        id: "",
        class: "table display wrap row-border table-hover",
        data: [],
        dataTable: {},
        configuration: {
          dom: 'B<"top"if>rt<"bottom"lp><"clear">',
          searching: true,
          paging: true,
          select: true,
          processing: true,
          responsive: true,
          destroy: true,
          info: true,
          ordering: true,
          keys: true,
          bSort: false,
          pageLength: 25,
          buttons: [
            {
              extend: "copy",
              text: "Copy to Clipboard",
              className: "btn btn-primary",
            },
            {
              extend: "csv",
              text: "Download Data",
              className: "btn btn-primary",
            },
          ],
        },
        rowSelected: false,
        rowSelectedId: 0,
      },
    };
  },
  props: {},
  methods: {
    getDataFromSelectedRow() {
      if (this.table.data) {
        return this.table.data.find((x) => x.id == this.table.rowSelectedId);
      } else {
        return {};
      }
    },
    getTableDataCallback(response) {
      this.$root.setLoading(false);
      this.table.data = response.data.data;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      const vm = this;
      this.table.dataTable
        .on("click", "tr", function (e, dt, type, indexes) {
          vm.table.rowSelected = true;
          var rowData = vm.table.dataTable.rows(this).data().toArray();
          $("#" + vm.table.id + " .selected").removeClass("selected");
          $(this).toggleClass("selected");
          if (!jQuery.isEmptyObject(rowData)) {
            vm.table.rowSelectedId = rowData[0].id;
          }
        })
        .on("deselect", function (e, dt, type, indexes) {
          vm.table.rowSelected = false;
          vm.table.rowSelectedId = 0;
          if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
          }
        });
      this.$notify({ group: "admin", clean: true });
      this.buildResponsiveTable();
    },
    buildResponsiveTable() {
      if (this.table.configuration.responsive) {
        const vm = this;
        setTimeout(function () {
          var responsiveTable = vm.table.dataTable;
          responsiveTable.responsive.rebuild();
          responsiveTable.responsive.recalc();
        }, 500);
      }
    },
    downloadItemError(error) {
      this.$notify(AdminService.displayErrorMessage({ text: "Error Downloading the file" }));
      ErrorService.processError("DataTableMixins.downloadItem", error, "survey-designer", this);
      this.$ga.exception("Error Downloading the file");
    },
    getDownloadFilename() {
      return this.getDataFromSelectedRow().downloadSurveyName;
    },
    downloadItemCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.getDownloadFilename() + ".xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
      this.setModalLoading(false);
      this.$notify(AdminService.displaySuccessMessage({ text: "Download Complete" }));
    },
    async downloadItem() {
      this.$root.setLoading(true);
      this.setModalLoading(true);
      this.$notify(
        AdminService.displayInfoMessage({
          text: "Please wait.......The downloaded file will appear at the bottom left-hand corner of the screen once it is complete",
          duration: -1,
        })
      );

      this.table.rowSelected = false;
      await axios({
        url: this.downloadItemUrl,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => this.downloadItemCallback(response))
        .catch((error) => this.downloadItemError(error));
    },
  },
  created() {},
};
</script>
