<template>
  <a class="mr-1" href="#" @click="showPopup">
    <font-awesome-icon :icon="['fa', 'info-circle']" />
  </a>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
library.add(faInfoCircle);

export default {
  name: "ModalLink",
  data() {
    return {};
  },
  destroyed() {},
  computed: {},
  mounted() {},
  methods: {
    showPopup() {
      this.$modal.show(this.modalId);
    },
  },
  watch: {},
  props: {
    modalId: {
      type: String,
      default: "",
    },
  },
};
</script>
