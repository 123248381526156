<template>
  <vue-modal :name="modalName" :resizable="true" :scrollable="true" :max-width="700" :width="'60%'" :height="'auto'"  @before-close="reset">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="reset">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <button id="print_button" class="btn btn-primary" type="button" v-on:click="printForm">Print Form</button>
        <survey-taker
            v-if="showSurvey"
            :view-only="true" 
            :read-only="true"
            :entry-responses="responses"
            :entry-client-id="this.$parent.clientId.toString()" 
            :entry-survey-url="this.$parent.survey.url" 
            :entry-survey-code="this.$parent.surveyCode">
        </survey-taker>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-2" @click="reset">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import SurveyTaker from "../interview-application/views/SurveyTaker.vue";
import ResponseService from "../services/response.service";
import AdminService from "../services/admin.service";

import SurveySummaryService from '../services/survey.summary.service';

export default {
  name: "SurveyResponses",
  components: {
    SurveyTaker
  },
  methods: {
      printForm(){
        SurveySummaryService.printForm(this.printTitle);
      },
      reset(){
        this.showSurvey = false;
      },
      getSurveyResponsesCallback(response){
        this.$root.setLoading(false);
        this.$notify({ group: "admin", clean: true });
        let statusCode = response.data.statusCode;
        if (statusCode == 200) {
            this.responses = response.data.data;
            this.showSurvey = true;
        } else {
            this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
        }          
      }
  },
  props: {
    modalName: {
      type: String,
      default: "survey-responses",
    },
    modalTitle: {
      type: String,
      default: "Form Responses",
    },
    surveyTakerId: {
      type: String,
      default: "",
    },
    printTitle: {
      type: String,
      default: "",
    },
    surveyId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
        responses: [],
        showSurvey: false
    };
  },
  computed: {},
  mounted() {},
  watch: {
    showSurvey: function(){
      if (this.showSurvey){
        this.$modal.show(this.modalName);
      } else {
        this.$modal.hide(this.modalName);
      }
    },
}
};
</script>
