<template>
  <div>
    <div v-show="sectionHeader.length > 0" class="header">
      {{ sectionHeader }}
    </div>
    <div v-show="sectionDescription.length > 0" class="description">
      {{ sectionDescription }}
    </div>
    <div v-if="displayHeader && !displayEmotionalHeader" class="question-header row no-gutters">
      <div v-if="labels.length > 0" class="col-4"></div>
      <div v-if="labels.length > 0" class="col-8 d-flex flex-row">
        <div class="text-nowrap pt-2">{{ labels[0] }}&nbsp;&lt;</div>
        <hr style="width: 100%; text-align: left; margin-top: 1.1rem; margin-left: 0; color: white; background-color: white" />
        <div class="text-nowrap pt-2 pr-1">&gt;&nbsp;{{ labels[1] }}</div>
      </div>
    </div>
    <div v-if="displayHeader && !displayEmotionalHeader" class="question-header row no-gutters">
      <div class="col-3">&nbsp;</div>
      <div class="col-9 form-check form-check-inline no-gutters">
        <div v-for="(headerOption, idxHeader) in headerOptions" v-bind:key="idxHeader" class="form-check-input col-lg-1">
          {{ headerOption.name }}
        </div>
      </div>
    </div>
    <div v-if="displayHeader && displayEmotionalHeader" class="question-header row no-gutters d-flex flex-row">
      <div v-if="labels.length > 0" class="col-3 text-right pt-2">{{ labels[0] }}&nbsp;&lt;</div>
      <div v-if="labels.length > 0" class="col-6">
        <hr style="width: 100%; text-align: left; margin-left: 0px; color: white; background-color: white" />
      </div>
      <div v-if="labels.length > 0" class="col-3 text-nowrap pt-2">&gt;&nbsp;{{ labels[1] }}</div>
    </div>
    <div :class="getQuestionClass(idxQuestion)" v-for="(question, idxQuestion) in questions" v-bind:key="idxQuestion">
      <survey-taker-question
        v-if="question.active == 'Y'"
        :base-url="baseUrl"
        :question-object="question"
        :disable-widgets="disableWidgets"
        @save="saveResult"
        @remove="removeResult"
      ></survey-taker-question>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import SurveyTakerQuestion from "./SurveyTakerQuestion.vue";
import SectionMixins from "../../mixins/SectionMixins.vue";
import jQuery from "jquery";

export default {
  name: "SurveyTakerSection",
  mixins: [SectionMixins],
  components: {
    SurveyTakerQuestion,
  },
  data() {
    return {};
  },
  props: {
    surveyId: {
      type: Number,
      default: 0,
    },
    surveyTakerId: {
      type: String,
      default: "",
    },
    surveyTakerIpAddress: {
      type: String,
      default: "",
    },
    disableWidgets: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rotateRows: function () {
      return this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs" || this.sectionTypeName == "Segmentation Information";
    },
    leftLabelClass: function () {
      if (this.displayEmotionalHeader) {
        return "col-3 text-right";
      } else {
        return "col-4";
      }
    },
    rightLabelClass: function () {
      if (this.displayEmotionalHeader) {
        return "col-3";
      } else {
        return "col-2";
      }
    },
    displayHeader: function () {
      if (this.sectionName.includes("Functional") || this.sectionName.includes("Emotional")) {
        return true;
      }
      if (this.sectionName.includes("Net Promoters") && this.questions.length > 0) {
        for (let questionIndex = 0; questionIndex < this.questions.length; questionIndex++) {
          let question = this.questions[questionIndex];
          if (
            question.questionType != null &&
            question.questionType != "undefined" &&
            question.questionType.name == "Competitor Company Code" &&
            question.questionType.name == "Competitor Company Code"
          ) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    displayEmotionalHeader: function () {
      return this.sectionName.includes("Emotional");
    },
    randomizeQuestions: function () {
      let field;
      let fieldName = this.randomizeFieldName;
      if (fieldName != "") {
        field = this.$parent.getSurveyField(fieldName);
      }
      return !jQuery.isEmptyObject(field) && field.surveyFieldValue == "Y";
    },
    headerOptions: function () {
      if (this.sectionName.includes("Functional") || this.sectionName.includes("Net Promoters")) {
        return [
          { name: "N/A" },
          { name: "1" },
          { name: "2" },
          { name: "3" },
          { name: "4" },
          { name: "5" },
          { name: "6" },
          { name: "7" },
          { name: "8" },
          { name: "9" },
          { name: "10" },
        ];
      } else {
        return [];
      }
    },
    labels: function () {
      if (this.sectionName.includes("Ideal Functional")) {
        return ["Not Important", "Very Important"];
      } else if (this.sectionName.includes("Functional")) {
        return ["Not Satisfied", "Very Satisfied"];
      } else if (this.sectionName.includes("Net Promoters")) {
        return ["Not Likely", "Very Likely"];
      } else if (this.sectionName.includes("Emotional")) {
        return ["More", "More"];
      } else {
        return [];
      }
    },
  },
  methods: {
    getQuestionClass: function (index) {
      if (this.rotateRows) {
        if (index % 2 == 0) {
          return "question even";
        } else {
          return "question odd";
        }
      }
      return "question";
    },
    shuffle: function (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    removeResult: function (result) {
      var match = this.$parent.$data.surveyResults.find((x) => Number(x.questionId) === Number(result));
      if (typeof match !== "undefined") {
        this.$parent.$data.surveyResults.splice($.inArray(match, this.$parent.$data.surveyResults), 1);
      }
    },
    saveResult: function (result) {
      var match = this.$parent.$data.surveyResults.find((x) => Number(x.questionId) === Number(result.questionId));
      if (typeof match !== "undefined") {
        this.$parent.$data.surveyResults.splice($.inArray(match, this.$parent.$data.surveyResults), 1);
      }
      this.$parent.$data.surveyResults.push(result);
      if (!this.$parent.$data.surveyStarted && !result.initialLoad) {
        this.$parent.setSurveyStarted();
      }
    },
  },
  created() {
    if (this.randomizeQuestions) {
      this.shuffle(this.questions);
    }
  },
  mounted() {},
};
</script>
<style scope="css">
.question-header i.right {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.question-header .left,
.question-header .right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
}
</style>
