import axios from "axios";
import moment from "moment";

import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import ClientService from "./client.service";

const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/survey-summary";

const GET_RESPONSE_SUMMARY = "Loading respondent data.....";
const UPDATE_RESPONSE_MESSAGE = "Updating response.....";

class ResponseService {
  async getResponseSummary(startDate, endDate, status, processed, callback, chatApp) {
    chatApp.$notify(AdminService.displayInfoMessage({ text: GET_RESPONSE_SUMMARY }, { duration: -1 }));
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("startDate", startDate);
      params.append("endDate", endDate);
      params.append("status", status);
      params.append("processed", processed);
      params.append("surveyId", SurveyService.getSurveyId());
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/get-response-summary", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ResponseService.getResponseSummary", error, "survey-designer", chatApp);
        });
    }
  }
  async getSurveyResponses(surveyId, surveyTakerId, callback, chatApp) {
    chatApp.$notify(AdminService.displayInfoMessage({ text: GET_RESPONSE_SUMMARY }, { duration: -1 }));
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("surveyTakerId", surveyTakerId);
      params.append("surveyId", surveyId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/get-survey-responses", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ResponseService.getSurveyResponses", error, "survey-designer", chatApp);
        });
    }
  }
  async updateProcessedResponse(surveyTakerId,surveyId,processed,callback,chatApp){
    chatApp.$notify(AdminService.displayInfoMessage({ text: UPDATE_RESPONSE_MESSAGE }, { duration: -1 }));
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("surveyTakerId", surveyTakerId);
      params.append("surveyId", surveyId);
      params.append("processed", processed);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/update-processed-response", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ResponseService.getSurveyResponses", error, "survey-designer", chatApp);
        });
    }
  }
  getResponseSummaryTableColumnData() {
    return [
      { title: "Id", data: "surveySummaryId", name: "surveySummaryId", width: "2rem" },
      { title: "Form Taker Id", data: "surveyTakerId", name: "surveyTakerId", width: "2rem" },
      { title: "First Name", data: "firstName", name: "firstName", width: "5rem" },
      { title: "Last Name", data: "lastName", name: "lastName", width: "5rem" }, 
      {
        title: "Status",
        data: "status",
        className: "",
        name: "status",
        width: "2rem",
        render: function (data, type, row) {
          var statusMap = {
            C: { label: "Complete", style: "warning" },
            I: { label: "Incomplete", style: "success" },
            O: { label: "Incomplete", style: "success" },
          };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        },
      },
      {
        title: "Date Started",
        data: "dateStarted",
        type: "date",
        name: "dateStarted",
        width: "3rem",
        render: function (data, type, row) {
          if (data != null) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
          } else {
            return "--";
          }
        },
      },
      {
        title: "Date Completed",
        data: "dateCompleted",
        type: "date",
        name: "dateCompleted",
        width: "3rem",
        render: function (data, type, row) {
          if (data != null) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
          } else {
            return "--";
          }
        },
      },
      {
        title: "Processed",
        bVisible: true,
        data: "processed",
        name: "id",
        width: "2rem",
        className: "select-checkbox text-center",
        render: function (data, type, full, meta) {
          let checkBoxObject = '<input type="checkbox" name="id[]" '
          if (data == 'Y'){
            checkBoxObject += ' checked '
          }
          checkBoxObject += ' value="' + data + '">';
          return checkBoxObject;
        },
      },
      {
        title: "",
        bVisible: true,
        data: "surveySummaryId",
        name: "id",
        width: "8rem",
        className: "select-checkbox text-center",
        render: function (data, type, full, meta) {
          //return '<input type="checkbox" name="id[]" value="' + data.surveySummaryId + '">';
          return '<button type="button" class="btn btn-primary ml-2"> View Responses </button>';
        },
      },
    ];
  }
}

export default new ResponseService();
