<template>
  <div v-show="showMenu">
    <div class="d-flex md-stepper-horizontal">
      <div v-bind:class="[this.isActivePage(1) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToInvitations">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'envelope']" />
        </div>
        <div class="md-step-title">Invitations</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(2) ? activeClass : '', notActiveClass]" v-on:click="goToRates">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'users']" />
        </div>
        <div class="md-step-title">Rates</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="goToWave">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'clock']" />
        </div>
        <div class="md-step-title">Waves</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(6) ? activeClass : '', notActiveClass]" v-on:click="goToMetrics">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'money-bill']" />
        </div>
        <div class="md-step-title">Metrics</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(4) ? activeClass : '', notActiveClass]" v-on:click="goToClusters">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'th-list']" />
        </div>
        <div class="md-step-title">Groups</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(5) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToDownload">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'database']" />
        </div>
        <div class="md-step-title">Download</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import VueRouter from "vue-router";
import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";

export default {
  name: "RespondentMenu",
  components: {},
  mixins: [MenuMixins],
  router,
  props: {},
  data() {
    return {};
  },
  methods: {
    goToDownload: function () {
      if (this.$router.currentRoute.name != "respondent-download") {
        this.$router.replace({ path: "/respondent/download" });
      }
    },
    goToRates: function () {
      this.$router.replace({ path: "/respondent/rates" });
    },
    goToClusters: function () {
      this.$router.replace({ name: "survey-group-list" });
    },
    goToWave: function () {
      this.$router.replace({ path: "/respondent/waves" });
    },
    goToMetrics: function () {
      this.$router.replace({ name: "metric-list" });
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>
