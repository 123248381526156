<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div v-show="!displayResponse" class="contact-us">
      <div class="form-row w-75">
        <div class="form-group form-inline w-100">
          <label for="name" class="col-3">Name</label>
          <input v-model="contactUsObject.name" id="name" name="name" type="text" class="form-control col-9" style="width: 100%" />
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group form-inline w-100">
          <label for="name" class="col-3">Email</label>
          <input v-model="contactUsObject.email" id="name" name="name" type="text" class="form-control col-9" style="width: 100%" />
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group form-inline w-100">
          <label for="name" class="col-3">Company</label>
          <input v-model="contactUsObject.company" id="name" name="name" type="text" class="form-control col-9" style="width: 100%" />
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group form-inline w-100">
          <label for="name" class="col-3">Phone</label>
          <input v-model="contactUsObject.phone" id="name" name="name" type="text" class="form-control col-9" style="width: 100%" />
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group form-inline w-100">
          <label for="name" class="col-3">Description</label>
          <textarea v-model="contactUsObject.description" id="name" name="name" type="text" class="form-control col-9" style="width: 100%" rows="5" />
        </div>
      </div>
      <div class="form-row w-75">
        <div class="col" role="group">
          <button type="submit" class="btn btn-primary float-right" @click="submitContactUsForm()">
            <span>Submit</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </div>
    </div>
    <div v-show="displayResponse" class="container contact-us">Thank You for your submission. Someone will contact you soon</div>
  </div>
</template>
<script>
import $ from "jquery";

import axios from "axios";

import AdminMixins from "../mixins/AdminMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

import authHeader from "../services/auth-header";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import UserService from "../services/user.service";

export default {
  name: "ContactUs",
  mixins: [AdminMixins, ViewMixins],
  components: {},
  props: {},
  data() {
    return {
      displayResponse: false,
      title: "Contact Us",
      contactUsObject: {},
    };
  },
  methods: {
    async submitContactUsForm() {
      this.$root.setLoading(true);
      const params = new URLSearchParams();
      params.append("contactUsObject", JSON.stringify(this.contactUsObject));
      params.append("userId", UserService.getUserId());
      await axios
        .post(AdminService.getBaseUrl() + "/api/v1/admin/save-contact-us", params, { headers: authHeader() })
        .then((response) => this.submitContactUsFormCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContactUs.submitContactUsForm", error, "admin", this);
        });
    },
    submitContactUsFormCallback: function (response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.displayResponse = true;
        this.contactUsObject = {};
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
  },
  created() {},
  mounted() {},
  computed: {},
};
</script>
