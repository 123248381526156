<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import ContentMixins from "./ContentMixins.vue";
import AdminService from "../services/admin.service";

export default {
  name: "ViewMixins",
  mixins: [ContentMixins],
  components: {},
  data() {
    return {
      error: false,
      displayView: false,
      message: "",
      modalLoading: false,
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl(),
    },
  },
  created() {
    this.getPageContent(this.title);
  },
  methods: {
    setDisplayView(setting) {
      this.displayView = setting;
    },
    getDisplayView(setting) {
      return this.displayView;
    },
    setModalLoading(setting) {
      this.modalLoading = setting;
    },
    getModalLoading() {
      return this.modalLoading;
    },
    postLoadData() {
      this.setDisplayView(true);
    },
  },
  watch: {},
  mounted() {},
  computed: {},
};
</script>
