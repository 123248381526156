import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import UserService from "./user.service";
import AdminService from "./admin.service";

const BASE_URL = AdminService.getBaseUrl();
const API_CONTENT_URL = BASE_URL + "/api/v1/content";
class ContentService {
  async getPageContentByPage(pageName, callback, chartApp) {
    if (typeof pageName != "undefined") {
      const params = new URLSearchParams();
      params.append("pageName", pageName);
      params.append("userId", 0);
      await axios
        .post(API_CONTENT_URL + "/get-content-by-page", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContentService.getPageContent", error, "chart", chartApp);
        });
    }
  }
  async getPageContent(pageName, callback, chartApp) {
    let userId = UserService.getUserId();
    if (typeof pageName != "undefined" && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("pageName", pageName);
      params.append("userId", userId);
      await axios
        .post(API_CONTENT_URL + "/get-content-by-page", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContentService.getPageContent", error, "chart", chartApp);
        });
    }
  }
  async getContentById(title, contentId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("contentId", contentId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CONTENT_URL + "/get-content-by-id", params, { headers: authHeader() })
      .then((response) => callback(response, title))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ContentService.getContentById", error, "admin", chartApp);
      });
  }
  getContentConfiguration() {
    return {
      direction: "ltr",
      height: 350,
      tabsize: 2,
      dialogsInBody: true,
      toolbar: [
        ["style", ["fontname", "fontsize", "color", "bold", "italic", "underline", "strikethrough", "clear"]],
        ["Paragraph style", ["style", "ol", "ul", "paragraph", "height"]],
        ["Insert", ["picture", "link", "table", "hr"]],
        ["Misc", ["fullscreen", "codeview", "undo", "redo", "help"]],
        ["mybutton", ["hello"]],
      ],
    };
  }
}
export default new ContentService();
