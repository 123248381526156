<template>
  <div class="login col-md-12">
    <div class="card card-container">
      <!-- <img id="profile-img" src="../assets/img/wrist-and-hand-center-tampa-logo-1.png" class="profile-img-card" />-->
      <notifications group="auth" position="top center" />
      <form v-show="displayForm" id="ResetPasswordFormId" name="form" @submit.prevent="resetPassword">
        <div class="form-group">
          <label for="userName">Your New Password</label>
          <input v-model="newPassword" id="newPassword" name="newPassword" class="form-control required passwordRequired" type="password" />
        </div>
        <div class="form-group">
          <label for="userName">Retype Your New Password</label>
          <input v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control required passwordRequired" type="password" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="this.$root.getLoading()">
            <span>Reset Password</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import $ from "jquery";
import jQuery from "jquery";

import User from "../models/user";
import authHeader from "../services/auth-header";
import ErrorService from "../services/error.service";
import AuthService from "../services/auth.service";
import AdminService from "../services/admin.service";
import ValidatorService from "../services/validator.service";

require("jquery-validation");

export default {
  name: "ResetPassword",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      entryToken: "",
      displayForm: false,
      validator: null,
      resetPasswordForm: null,
    };
  },
  created() {
    this.entryToken = this.$route.query.token;
    this.verifyToken();
  },
  components: {},
  computed: {},
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  methods: {
    setupResetPasswordForm() {
      this.resetPasswordForm = $("#ResetPasswordFormId");
      jQuery.validator.setDefaults({
        debug: true,
        success: "valid",
      });
      jQuery.validator.addMethod(
        "notEqual",
        function (value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );
      jQuery.validator.addClassRules("passwordRequired", { required: true, minlength: 8 });
      jQuery.validator.addMethod("passwordCheck", ValidatorService.passwordCheck, ValidatorService.passwordErrorMessage);
      this.validator = this.resetPasswordForm.validate({
        rules: {
          confirmPassword: {
            equalTo: "#newPassword",
          },
        },
        highlight: function (element) {
          $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
        },
        unhighlight: function (element) {
          $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function (error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        },
      });
    },
    verifyTokenCallback(response) {
/*
        let statusCode = response.data.statusCode;
        if (statusCode == 200) {
          this.displayForm = true;
          this.setupResetPasswordForm();
          this.$notify(AdminService.displaySuccessMessage({ text: response.data.message, group: "auth" }));
        } else {
          this.$notify(AdminService.displayErrorMessage({ text: response.data.message, group: "auth" }));
        }
*/
    },
    async verifyToken() {
/*
      await AuthService.verifyToken(this.entryToken, this.verifyTokenCallback).catch((error) => {
        error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ResetPassword.verifyToken", error, "admin", this);
        });        
*/
    },
    resetPasswordCallback(response) {
/*      
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message, group: "auth" }));
        this.$router.push({
          name: "login",
          params: {
            initialMessage: response.data.message,
          },
        });
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message, group: "auth" }));
      }
*/
    },
    async resetPassword() {
/*      
      if (!this.resetPasswordForm.valid()) {
        return false;
      } else {      
        this.$root.setLoading(true);
        await AuthService.resetPassword(this.newPassword, this.entryToken, this.resetPasswordCallback).catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ResetPassword.resetPassword", error, "admin", this);
        });
      }
*/
    },
  },
};
</script>
