<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <study-design-menu></study-design-menu>
    <div v-if="getDisplayView()" class="manage-invitations">
      <!--      
      <div class="row no-gutters">
        <div class="col">
          <modal-link modal-id="Survey Mode"></modal-link>
          <span class="section-label">Survey Mode</span>
        </div>
      </div>      
-->
      <div class="row no-gutters border-bottom pt-2 pb-3">
        <div class="col-lg-6 col-sm-12">
          <survey-field
            ref="surveyActiveMode"
            :display-label="false"
            :disable-field="isBasicUser"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('survey-mode')"
            label-class="col-2 pr-2"
            field-class="form-group form-inline mb-2"
            field-type="radio"
            :field-options="radioOptions"
            default-field-name="survey-mode"
            default-field-value="open"
            @save="this.setActiveModeOptions"
          ></survey-field>
          <survey-field
            v-show="displayRespondents"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('survey-closed-number-of-respondents')"
            :display-modal="false"
            label-class="col-4"
            field-class="form-control col-1 p-1"
            field-type="text-box"
            field-label="# of Respondents"
            default-field-value=""
            default-field-name="survey-closed-number-of-respondents"
            @save="this.updateSurveyStats"
          ></survey-field>
          <survey-field
            v-show="displayClosedMessage"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('survey-closed-message')"
            :display-modal="false"
            label-class="col-4"
            field-class="form-control col-8 mb-1 p-1"
            field-type="text-box"
            field-label="Form Closed Message"
            default-field-value=""
            default-field-name="survey-closed-message"
            @save="this.saveSurveyFieldWithSuccessMessage"
          ></survey-field>
        </div>
        <div class="col-lg-6 col-sm-12">
          <!--          
          <div>
            <span class="section-label pl-3">Survey Status: </span> 
            <span v-if="this.survey.active == 'Y'" class="text-success"> Active </span>
            <span v-else class="text-danger"> Not Active </span>
          </div>
          <div>
            <span class="section-label pl-3">Build Survey Required: </span> 
            <span v-if="this.survey.buildRequired == 'Y'" class="text-danger"> Yes </span>
            <span v-else class="text-success">No</span>
          </div>
-->
          <div v-if="numberOfCompletedSurveys > 0">
            <span class="section-label pl-3"># of Completed Surveys : </span>
            <span class="text-success">{{ this.numberOfCompletedSurveys }} </span>
          </div>
          <div v-if="remainingSurveys > 0">
            <span class="section-label pl-3"># of Remaining Surveys : </span>
            <span class="text-danger">{{ this.remainingSurveys }} </span>
          </div>
        </div>
      </div>
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-lg-6 col-sm-12">
          <!--          
          <div class="row no-gutters">
            <div class="col-3">
              <modal-link modal-id="Send Invitations"></modal-link>
              <span class="section-label">Send Invitations</span>
            </div>
          </div>
-->
          <div class="row no-gutters">
            <div class="col-lg-12 col-sm-12">
              <div class="form-group form-inline">
                <span>{{ this.baseInterviewUrl + "/" + this.clientId }}/s/</span>
                <input v-model="surveyUrl" id="surveyUrl" name="surveyUrl" type="text" class="form-control col-4" :disabled="isBasicUser" @blur="saveSurveyUrl()" />
                <button v-clipboard="fullUrl" class="btn btn-primary float-right ml-2">Copy Link</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!--          
          <survey-field
            ref="enableUniqueCode"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('enable-unique-codes')"
            field-class="form-group form-inline"
            field-type="checkbox"
            field-label="Use Respondent Codes"
            default-field-name="enable-unique-codes"
            default-field-value=""
            @save="this.enableUniqueCodeUploadEvent"
          ></survey-field>
          <br />
          Select a file to upload
          <input ref="uniqueCodeUpload" type="file" @change="onFileChange" :disabled="!useRespondentCodes" />
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">Import File</button>
-->
        </div>
      </div>
      <!--      
      <div class="row pt-5">
        <div class="col">
          <table id="manage-codes-data" class="table display wrap row-border table-hover" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>Unique Code Id</th>
                <th>Survey Name</th>
                <th>Email Address</th>
                <th>Unique Codes</th>
                <th>URL</th>
                <th>Status</th>
                <th>Date Created</th>
                <th>Date Completed</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
-->
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";
import moment from "moment";

Vue.prototype.moment = moment;
require("jquery-validation");

import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import SurveyField from "../../components/SurveyField.vue";

import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";
//import SurveyStatus from "../../components/SurveyStatus.vue";

export default {
  name: "ManageInvitations",
  mixins: [StudyDesignMixins, DataTableMixins],
  components: {
    SurveyField,
  },
  data() {
    return {
      title: "Manage Forms",
      table: {
        id: "manage-codes-data",
        columns: [
          {
            title: "Unique Code Id",
            data: "surveyUniqueCodeId",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "6rem",
          },
          { title: "Survey Name", data: "surveyName", name: "surveyName", width: "5rem" },
          { title: "Email Addres", data: "emailAddress", name: "emailAddress", width: "5rem" },
          { title: "Unique Codes", data: "uniqueCode", name: "uniqueCode", width: "5rem" },
          { title: "Url", data: "url", name: "url", width: "5rem" },
          {
            title: "Status",
            data: "status",
            className: "text-center",
            name: "status",
            width: "4rem",
            render: function (data, type, row) {
              var statusMap = { C: { label: "Complete", style: "warning" }, R: { label: "Ready", style: "success" } };
              if (type === "display") {
                return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
              } else {
                return data;
              }
            },
          },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            width: "6rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
            },
          },
          {
            title: "Date Completed",
            data: "dateCompleted",
            type: "date",
            name: "updateDate",
            width: "6rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
            },
          },
        ],
      },
      pageNumber: 6,
      useRespondentCodes: false,
      uniqueCodefile: "",
      surveyUrl: "",
      displayRespondents: false,
      displayClosedMessage: false,
      numberOfCompletedSurveys: -1,
      maximumSurveys: -1,
      radioOptions: [
        { checked: false, name: "Open", value: "open" },
        { checked: false, name: "Closed", value: "closed" },
        { checked: false, name: "Closed (based on completed surveys)", value: "closed-by-respondents" },
      ],
    };
  },
  methods: {
    updateSurveyStats: function (result) {
      if (!isNaN(result.surveyFieldValue)) {
        this.maximumSurveys = Number(result.surveyFieldValue);
      } else {
        this.maximumSurveys = -1;
      }
      this.saveSurveyFieldWithSuccessMessage(result);
    },
    setActiveModeOptions: function (result) {
      if (result.surveyFieldValue == "closed-by-respondents") {
        this.displayRespondents = true;
        this.displayClosedMessage = true;
      } else if (result.surveyFieldValue == "closed") {
        this.displayRespondents = false;
        this.displayClosedMessage = true;
      } else {
        this.displayRespondents = false;
        this.displayClosedMessage = false;
      }
    },
    onFileChange: function () {
      this.uniqueCodefile = this.$refs.uniqueCodeUpload.files[0];
    },
    async importFile() {
      let formData = new FormData();
      formData.append("file", this.uniqueCodefile);
      formData.append("surveyId", this.survey.surveyId);
      formData.append("userId", UserService.getUserId());

      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, authHeader());

      await axios
        .post(this.baseUrl + "/api/v1/survey-design/import-unique-codes", formData, { headers: headers })
        .then((response) => this.importFileCallback(response))
        .catch((error) => {
          ErrorService.processError("ManageInvitations.ManageInvitations", error, "survey-designer", this);
        });
    },
    importFileCallback: function (response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async saveSurveyUrl() {
      if (this.survey.url != this.surveyUrl) {
        const params = new URLSearchParams();
        params.append("surveyUrl", this.surveyUrl);
        params.append("surveyId", this.survey.surveyId);
        params.append("clientId", this.clientId);
        params.append("userId", UserService.getUserId());
        await axios
          .post(this.baseUrl + "/api/v1/survey-design/save-survey-url", params, { headers: authHeader() })
          .then((response) => this.saveSurveyUrlCallback(response))
          .catch((error) => {
            ErrorService.processError("ManageInvitations.saveSurveyUrl", error, "survey-designer", this);
          });
      }
    },
    saveSurveyUrlCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyUrl = response.data.data;
        this.survey.url = response.data.data;
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    enableUniqueCodeUploadEvent: function (result) {
      this.saveSurveyField(result);
      this.useRespondentCodes = result.surveyFieldValue == "Y";
    },
    postLoadData: function () {
      this.getTableData();
      this.setDisplayView(true);
      if (typeof this.getSurveyField("enable-unique-codes") != "undefined") {
        this.useRespondentCodes = this.getSurveyField("enable-unique-codes").surveyFieldValue;
      }
      if (typeof this.getSurveyField("survey-closed-number-of-respondents") != "undefined") {
        this.maximumSurveys = Number(this.getSurveyField("survey-closed-number-of-respondents").surveyFieldValue);
      }
      if (typeof this.getSurveyField("survey-mode") != "undefined") {
        this.setActiveModeOptions(this.getSurveyField("survey-mode"));
      }
      this.surveyUrl = this.survey.url;
    },
    async getTableData() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Loading table data please wait....." }));
      const params = new URLSearchParams();
      params.append("clientId", this.clientId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(this.baseUrl + "/api/v1/survey-design/get-survey-unique-codes-by-client-id", params, { headers: authHeader() })
        .then((response) => this.getTableDataCallback(response))
        .catch((error) => {
          ErrorService.processError("ManageInvitations.getTableData", error, "survey-designer", this);
        });
    },
  },
  computed: {
    baseInterviewUrl: function () {
      return typeof process.env.VUE_APP_INTERVIEW_URL != "undefined" ? process.env.VUE_APP_INTERVIEW_URL : "https://forms.wristandhandcenterfl.com";
    },
    fullUrl: function () {
      return this.baseInterviewUrl + "/" + this.clientId + "/s/" + this.surveyUrl;
    },
    remainingSurveys: function () {
      if (this.maximumSurveys > 0 && this.numberOfCompletedSurveys > 0) {
        return this.maximumSurveys - this.numberOfCompletedSurveys;
      }
      return -1;
    },
  },
  created() {},
  props: {},
  mounted() {},
};
</script>
