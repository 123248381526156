<template>
  <div v-if="isTextBox" class="form-group form-inline no-gutters">
    <label v-if="displayLabel" :for="surveyFieldId" :class="labelClass">
      <modal-link v-if="displayModal" :modal-id="modalName"></modal-link>
      <span class="section-label pr-3">{{ fieldLabel }}</span>
    </label>
    <input
      v-model="surveyValue"
      :id="surveyFieldId"
      :name="surveyFieldId"
      type="text"
      :class="fieldClass"
      :disabled="disableField"
      style="width: 100%"
      @blur="saveSurveyFieldEvent($event)"
    />
  </div>
  <div v-else-if="isRadio" :class="fieldClass">
    <label v-if="displayLabel" :for="surveyFieldId" :class="labelClass">
      <modal-link v-if="displayModal" :modal-id="modalName"></modal-link>
      <span class="section-label pr-3">{{ fieldLabel }}</span>
    </label>
    <div class="form-check form-check-inline" v-for="(radioOption, idxRadio) in this.fieldOptions" v-bind:key="idxRadio">
      <input
        v-model="surveyValue"
        type="radio"
        :class="'form-check-input'"
        :disabled="disableField"
        :id="surveyFieldId"
        :name="surveyFieldId"
        :value="radioOption.value"
        @click="saveSurveyFieldEvent($event)"
      />{{ radioOption.name }}
    </div>
  </div>
  <div v-else-if="isCheckbox" :class="fieldClass">
    <input
      type="checkbox"
      class="form-check-input"
      :id="surveyFieldId"
      :name="surveyFieldId"
      :disabled="disableField"
      v-model="surveyValue"
      true-value="Y"
      false-value="N"
      @click="saveSurveyFieldEvent($event)"
    />
    <label class="custom-checkout" v-if="displayLabel" :for="surveyFieldId">{{ fieldLabel }}</label>
  </div>
  <div v-else-if="isTextArea" :class="fieldClass">
    <div v-if="displayLabel" :for="surveyFieldId" class="section-label">{{ fieldLabel }}</div>
    <textarea
      style="width: 100%"
      class="form-control"
      :id="surveyFieldId"
      :name="surveyFieldId"
      :disabled="disableField"
      v-model="surveyValue"
      @blur="saveSurveyFieldEvent($event)"
      rows="5"
    >
    </textarea>
  </div>
  <div v-else-if="isDropdown" class="form-group form-inline">
    <label v-if="displayLabel" :for="surveyFieldId" class="section-label">{{ fieldLabel }}</label>
    <select
      v-model="surveyValue"
      :class="fieldClass"
      class=""
      :id="surveyFieldId"
      :name="surveyFieldId"
      :disabled="disableField"
      style="width: 100%"
      @blur="saveSurveyFieldEvent($event)"
    >
      <option v-for="(dropdownOption, idxDropdown) in this.fieldOptions" :selected="dropdownOption.selected" :value="dropdownOption.value" v-bind:key="idxDropdown">
        {{ dropdownOption.name }}
      </option>
    </select>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import ContentMixins from "../mixins/ContentMixins.vue";
import authHeader from "../services/auth-header";
import AdminService from "../services/admin.service";
import ErrorService from "../services/error.service";
import UserService from "../services/user.service";

export default {
  name: "SurveyField",
  mixins: [ContentMixins],
  data() {
    return {
      initialLoad: true,
      surveyValue: "",
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl(),
    },
    buildSurvey: {
      type: Boolean,
      default: false,
    },
    disableField: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      default: 0,
    },
    fieldClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "col-3",
    },
    defaultFieldName: {
      type: String,
      default: "",
    },
    defaultFieldValue: {
      type: String,
      default: "",
    },
    fieldLabel: {
      type: String,
      default: "",
    },
    fieldType: {
      type: String,
      default: "",
    },
    fieldOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    modalName: {
      type: String,
      default: "",
    },
    displayModal: {
      type: Boolean,
      default: false,
    },
    surveyFieldObject: {
      type: Object,
      default: function () {
        return {
          surveyFieldId: 0,
          surveyFieldName: "",
          surveyFieldValue: "",
        };
      },
    },
  },
  created() {},
  methods: {
    async saveSurveyField(newValue) {
      if (this.surveyFieldObject.surveyFieldValue !== newValue || this.isCheckbox || this.isRadio) {
        this.$parent.disableButton = true;
        var result = {
          surveyFieldId: this.surveyFieldObject.surveyFieldId,
          surveyFieldName: this.surveyFieldName,
          surveyFieldValue: newValue,
          buildSurvey: this.buildSurvey,
        };
        this.$emit("save", result);
        const params = new URLSearchParams();
        params.append("surveyId", this.surveyId);
        params.append("name", result.surveyFieldName);
        params.append("value", result.surveyFieldValue);
        params.append("userId", UserService.getUserId());
        await axios
          .post(this.baseUrl + "/api/v1/survey-design/save-survey-field", params, { headers: authHeader() })
          .then((response) => this.saveSurveyFieldCallback(response))
          .catch((error) => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("SurveyField.saveSurveyField", error, "survey-designer", this);
          });
      }
    },
    processError: function (error) {
      this.$parent.$data.message = error.response.data.message;
      this.$parent.$data.error = true;
    },
    saveSurveyFieldEvent: function (event) {
      let newValue;
      if (this.isCheckbox) {
        newValue = event.target.checked ? "Y" : "N";
      } else {
        newValue = event.target.value;
      }
      this.saveSurveyField(newValue);
    },
    saveSurveyFieldCallback: function (response) {
      this.$parent.disableButton = false;
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyFieldObject.surveyFieldId = response.data.surveyFieldId;
        this.$parent.$data.error = false;
      } else {
        this.$parent.$data.message = response.data.message;
        this.$parent.$data.error = true;
      }
    },
  },
  watch: {},
  mounted() {
    if (this.surveyFieldObject.surveyFieldName == "") {
      this.surveyFieldObject.surveyFieldName = this.defaultFieldName;
    }
    if (this.surveyFieldObject.surveyFieldValue != "") {
      this.surveyValue = this.surveyFieldObject.surveyFieldValue;
    } else if (this.defaultFieldValue != "") {
      this.surveyValue = this.defaultFieldValue;
      this.saveSurveyField(this.defaultFieldValue);
    }
    this.initialLoad = false;
  },
  computed: {
    surveyFieldId: function () {
      return "survey_field_" + this.surveyFieldObject.surveyFieldId;
    },
    surveyFieldName: function () {
      if (this.surveyFieldObject.surveyFieldName) {
        return this.surveyFieldObject.surveyFieldName;
      } else {
        return "";
      }
    },
    displayLabel: function () {
      return !jQuery.isEmptyObject(this.fieldLabel);
    },
    isTextArea: function () {
      return this.fieldType === "text-area";
    },
    isTextBox: function () {
      return this.fieldType === "text-box";
    },
    isCheckbox: function () {
      return this.fieldType === "checkbox";
    },
    isRadio: function () {
      return this.fieldType === "radio";
    },
    isDropdown: function () {
      return this.fieldType === "dropdown";
    },
  },
};
</script>
