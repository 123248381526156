<template>
  <div class="panel-admin-body">
    <div class="title pb-1">
      <modal-link modal-id="How to Use"></modal-link>
      {{ title }}
    </div>
    <study-design-menu></study-design-menu>
    <div class="respondent-download">
      <a
        href="#"
        @click.prevent="downloadItem()"
        id="DownloadCompleteSurveyData"
        :class="[this.table.rowSelected ? 'btn btn-primary float-right mb-4' : 'btn btn-primary float-right mb-4 disabled']"
        role="button"
        aria-disabled="true"
      >
        <span class="align-text-bottom">Download Form Data <font-awesome-icon :icon="['fa', 'download']" /></span>
        <span v-show="getModalLoading()" class="mb-1 align-text-bottom spinner-border spinner-border-sm"></span>
      </a>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";

import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";
import SurveyService from "../../services/survey.service";

import ClientService from "../../services/client.service";
import SurveySummaryService from "../../services/survey.summary.service";

export default {
  name: "FormResponses",
  mixins: [StudyDesignMixins, DataTableMixins],
  components: {},
  props: {},
  data() {
    return {
      table: {
        id: "respondent-download-data",
        configuration: {
          dom: '<"top"if>rt<"bottom"lp><"clear">',
          searching: false,
          paging: true,
          select: true,
          destroy: true,
          info: false,
          ordering: true,
          keys: true,
          bSort: false,
        },
        columns: SurveySummaryService.getTableColumnData(),
      },
      pageNumber: 1,
      clientTimePeriods: [],
      downloadSets: [],
      title: "Form Responses",
    };
  },
  methods: {
    async getTableData() {
      this.$root.setLoading(true);
      SurveySummaryService.getSummaryDataBySurveyId(this.getTableDataCallback, this);
    },
    getDownloadFilename() {
      return this.clientName + " - Form Respondents";
    },
    loadData: function () {
      this.downloadSets = [];
      this.getTableData();
      let vm = this;
      $("#respondent-download-data tbody").on("click", 'input[type="checkbox"]', function (e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!jQuery.isEmptyObject(data)) {
          if (e.target.checked) {
            let downloadSet = new Object();
            downloadSet.id = data.id;
            downloadSet.surveyId = data.surveyId;
            vm.downloadSets.push(downloadSet);
            vm.clientTimePeriods.push(data.id);
          } else {
            let removedItem = vm.clientTimePeriods.find((x) => x == data.id);
            if (typeof removedItem !== "undefined") {
              vm.clientTimePeriods.splice($.inArray(removedItem, vm.clientTimePeriods), 1);
              vm.downloadSets.splice($.inArray(removedItem, vm.downloadSets), 1);
            }
          }
        }
      });

      SurveySummaryService.processPartialResponsesForSurvey(SurveyService.getSurveyId(), -1, this.processPartialResponsesCallback, this);
      this.table.configuration.order = [
        [1, "desc"],
        [8, "desc"],
      ];
    },
    processPartialResponsesCallback: function (response) {},

    async downloadItem() {
      this.$root.setLoading(true);
      this.setModalLoading(true);
      this.$notify(
        AdminService.displayInfoMessage({
          text: "Please wait.......The downloaded file will appear at the bottom left-hand corner of the screen once it is complete",
          duration: -1,
        })
      );
      this.table.rowSelected = false;
      await SurveySummaryService.getDownloadSurveyDataUrlMultiple(this.downloadSets, this.downloadItemCallback, this);
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>
