import $ from "jquery";
import axios from "axios";
import authHeader from "./auth-header";
import AdminService from "./admin.service";
import ClientService from "./client.service";
import jQuery from "jquery";

const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/admin";

class ErrorService {
  async logException(location, err, type) {
    const params = new URLSearchParams();
    params.append("error_message", err);
    params.append("location", location);
    params.append("error_type", type);
    params.append("client_id", ClientService.getClientId());
    await axios
      .post(API_URL + "/process-error", params, { headers: authHeader() })
      .then((response) => console.log(JSON.stringify(response)))
      .catch((error) => console.log(JSON.stringify(error)));
  }
  async processException(location, err, type, panelApp) {
    let logger = panelApp.$log;
    logger.error("Error: " + err);

    this.logException(location, err, type);

    var error_message = "Type: " + type + " Error Message: " + err;
    panelApp.$notify(AdminService.displayErrorMessage({ text: error_message }));
    error_message += "     Location: " + location;
    error_message += "     Axios Info: " + this.axiosErrorMessage(err);

    panelApp.$ga.exception(error_message);
    if (typeof panelApp.$root.setLoading != "undefined") {
      panelApp.$root.setLoading(false);
    }
  }
  mergeError(response) {
    let errorMessage = "";
    if (typeof response != "undefined" && typeof response.data != "undefined" && typeof response.data.message != "undefined") {
      errorMessage += " : ";
      errorMessage += response.data.message;
    } else {
      errorMessage += " - No response from server";
    }
    return errorMessage;
  }
  axiosErrorMessage(error) {
    let axiosErrorMessage = " ";
    if (typeof error != "undefined" && typeof error.config != "undefined" && typeof error.config.url != "undefined") {
      axiosErrorMessage += "     URL: " + error.config.url;
      if (typeof error.config.data != "undefined") {
        axiosErrorMessage += "     Data: " + error.config.data;
      }
    } else {
      axiosErrorMessage = " Axios data not available";
    }
    return axiosErrorMessage;
  }

  async processError(location, response, type, panelApp) {
    if (typeof panelApp.$root.setLoading != "undefined") {
      panelApp.$root.setLoading(false);
    }
    let logger = panelApp.$log;
    logger.error("Response: " + JSON.stringify(response));
    var error_message;
    if (response.message === "") {
      error_message = "Error Connecting to the Server ";
    } else {
      error_message = response.message;
    }
    if (error_message === null) {
      error_message = response.debugMessage;
    }
    if (type == "chart") {
      error_message = " " + error_message;
    }
    error_message = "Type: " + type + "     Error Message:  " + error_message;

    panelApp.$notify(AdminService.displayErrorMessage({ text: error_message }));
    error_message += " Location: " + location;
    error_message += " Axios Info: " + this.axiosErrorMessage(response);
    panelApp.$ga.exception(error_message);

    const params = new URLSearchParams();
    params.append("error_message", response);
    params.append("location", location);
    params.append("error_type", type);
    params.append("client_id", ClientService.getClientId());
    await axios
      .post(API_URL + "/process-error", params, { headers: authHeader() })
      .then((response) => logger.error(JSON.stringify(response)))
      .catch((error) => logger.error(JSON.stringify(error)));
  }
  setupValidationForm() {
    jQuery.validator.setDefaults({
      debug: true,
      success: "valid",
    });
    jQuery.validator.addMethod(
      "notEqual",
      function (value, element, param) {
        return this.optional(element) || value != param;
      },
      "Please choose a value!"
    );
    jQuery.validator.addMethod(
      "phoneUS",
      function (phone_number, element) {
        phone_number = phone_number.replace(/\s+/g, "");
        return this.optional(element) || (phone_number.length > 9 && phone_number.match(/^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/));
      },
      "Please specify a valid phone number."
    );
    jQuery.validator.addMethod(
      "selectNone",
      function (value, element) {
        if (element.value == "0" || element.value == "-1" || element.value == "") {
          return false;
        } else return true;
      },
      "Please select an option."
    );
    jQuery.validator.addMethod(
      "notSure",
      function (value, element) {
        return $(element).parent().parent().find(":checked").length > 0 && $(element).parent().parent().find(":checked").val() != "0";
      },
      "Please select an option."
    );
    return {
      rules: {},
      highlight: function (element) {
        $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
      },
      unhighlight: function (element) {
        $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
      },
      errorElement: "medium",
      errorClass: "form-text text-danger",
      errorPlacement: function (error, element) {
        if (element.length) {
          if (element.is(":radio") || element.is(":checkbox")) {
            error.appendTo(element.parents(".question-radio-label"));
          } else {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        }
      },
    };
  }
}

export default new ErrorService();
