<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container user-list">
      <div class="row no-gutters">
        <div class="pr-2">
          <router-link :to="{ name: 'add-user' }">
            <button type="button" class="btn btn-success" id="AddNewUserButton">
              Add User
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'plus-circle']" />
              </span>
            </button>
          </router-link>
        </div>
        <div class="pr-2">
          <router-link :to="{ name: 'edit-user', params: { initialUserId: this.table.rowSelectedId } }">
            <button type="button" class="btn btn-primary" id="EditUserButton" :disabled="!table.rowSelected">
              Edit User
              <span class="ml-auto">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
            </button>
          </router-link>
        </div>
        <div class="pr-2">
          <router-link :to="{ name: 'duplicate-user', params: { initialUserId: this.table.rowSelectedId, initialMode: 'duplicate-user' } }">
            <button type="button" class="btn btn-primary" id="EditUserButton" :disabled="!table.rowSelected">
              Copy User
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'copy']" />
              </span>
            </button>
          </router-link>
        </div>
        <div class="pr-2">
          <a class="pr-2" href="#" @click="displayDeleteConfirmation('Confirmation User')">
            <button type="button" class="btn btn-primary" id="DeleteSurveyButton" :disabled="!table.rowSelected">
              Delete User
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'minus-circle']" />
              </span>
            </button>
          </a>
        </div>
        <div class="col d-flex justify-content-end pr-2">
          <a class="pr-2" href="#" @click="$modal.show('import-user-file')">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Users <font-awesome-icon :icon="['fa', 'download']" /></span>
            </button>
          </a>
          <a href="/api/user/download-user-import-template" @click.prevent="downloadItem()" id="DownloadUserImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download User Import Template <font-awesome-icon :icon="['fa', 'download']" /></span>
            </button>
          </a>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-sm-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>User Id</th>
                <th>User Name</th>
                <th>Email</th>
                <th>User Type</th>
                <th>Client</th>
                <th>Active</th>
                <th>Date Created</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
    <vue-modal name="import-user-file" :width="368" :height="400">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import User Data</h5>
        </div>
        <div class="form-group form-row p-2 text-success">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importUserUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              $modal.hide('import-user-file');
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
    <modal
      :modal-id="'Confirmation User'"
      :modal-title="'Delete Confirmation'"
      :modal-content="'Are you sure you want delete this user ?'"
      :modal-result-id="table.rowSelectedId"
      :include-confirmation="true"
      @confirm="deleteUser"
    />
  </div>
  <div v-else class="panel-admin-body npa-email-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";

export default {
  name: "UserList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importUserImportFile: "",
      title: "User List",
      table: {
        id: "user-list-table",
        columns: [
          {
            data: "id",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            width: "3rem",
            name: "id",
          },
          { data: "userName", name: "userName", width: "20rem" },
          { data: "email", name: "email", width: "20rem" },
          { data: "userTypeName", name: "userTypeName", width: "15rem" },
          { data: "clientName", name: "clientName", width: "15rem" },
          {
            data: "active",
            className: "text-center",
            name: "status",
            width: "5rem",
            render: function (data, type, row) {
              var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
              if (type === "display") {
                return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
              } else {
                return data;
              }
            },
          },
          {
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            width: "5rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
            },
          },
          {
            data: "dateModified",
            type: "date",
            name: "dateModified",
            width: "5rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
            },
          },
        ],
      },
    };
  },
  methods: {
    async getTableData() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Retrieving users please stand by.....", duration: -1 }));
      await axios
        .get(this.baseUrl + "/api/user/get-user-list", { headers: authHeader() })
        .then((response) => this.getTableDataCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("UserList.getTableData", error, "admin", this);
        });
    },
    deleteUser() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Deleting user please wait.....", duration: -1 }));
      UserService.deleteUser(this.table.rowSelectedId, this.deleteUserCallback, this);
    },
    deleteUserCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        this.getTableData();
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    getDownloadFilename() {
      return "User Import Template";
    },
    onFileChange: function () {
/*      
      this.importUserImportFile = this.$refs.importUserUpload.files[0];
*/
    },
    async importFile() {
/*      
      this.setModalLoading(true);
      this.$root.setLoading(true);
      this.modalReturnMessage = "Importing user data please stand by.....";

      let formData = new FormData();
      formData.append("file", this.importUserImportFile);

      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, authHeader());

      await axios
        .post(this.baseUrl + "/api/user/import-users", formData, { headers: headers })
        .then((response) => this.importFileCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("UserList.importFile", error, "admin", this);
        });
*/
    },
    importFileCallback: function (response) {
/*  
      this.setModalLoading(false);
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });

      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.table.dataTable.draw(true);
        this.$notify(AdminService.displaySuccessMessage({text: response.data.message}));
      } else {
        this.$notify(AdminService.displayErrorMessage({text: response.data.message}));
      }
*/
    },
  },
  computed: {
/*    
    downloadItemUrl: function(){
      return this.baseUrl + "/api/user/download-user-import-template";
    },
*/
  },
  created() {
    if (this.isAdmin) {
      this.getTableData();
    }
  },
  props: {},
  mounted() {},
};
</script>
