<template>
  <div class="section shadow mt-2 mb-2">
    <div v-show="!isEditMode">
      <div class="row no-gutters section-header pl-2">
        <div class="align-self-center col-9">{{ section.sequenceNumber }}. {{ this.sectionTypeName }}</div>
        <div :class="[sectionTypeName == 'Emotional Needs' ? 'align-self-center col-3 d-line ' : 'align-self-center col-3 section-actions']">
          <button type="submit" class="btn btn-primary float-right ml-2 mr-2" v-on:click="mode = 'edit'">{{ primaryButtonText }}</button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <div v-if="displayHeader" class="header p-2">{{ this.sectionHeader }}</div>
          <div class="description">{{ sectionDescription }}</div>
        </div>
      </div>
      <div class="row no-gutters" v-for="(question, idxQuestion) in this.activeQuestions" v-bind:key="idxQuestion">
        <question
          v-if="question.active == 'Y'"
          :ref="idxQuestion"
          :question-index="idxQuestion"
          :question-object="question"
          :section-object="section"
          :display-details="true"
          :display-required="displayRequired"
          :display-include-as-segment="displayIncludeAsSegment"
          :is-question-in-emotional-section="false"
          :widget-type-options="widgetTypeOptions.options"
          :disable-edit-mode="disableEditMode"
        ></question>
      </div>
      <div class="row no-gutters justify-content-end">
        <div class="form-group mr-2" v-if="(displayRandomizeEmotionalQuestions || displayRandomizeFunctionalQuestions) && this.randomizeQuestionFieldName != ''">
          <survey-field
            :survey-id="surveyId"
            :survey-field-object="this.$parent.getSurveyField(this.randomizeQuestionFieldName)"
            field-type="checkbox"
            field-class="form-group form-inline"
            field-label="Randomize Questions"
            :default-field-name="this.randomizeQuestionFieldName"
            default-field-value="Y"
          ></survey-field>
        </div>
      </div>
      <div class="row no-gutters" v-show="isAddQuestionMode && !isEditMode">
        <add-question
          :new-question-index="this.activeQuestions.length + 1"
          :survey-id="this.surveyId"
          :section-id="this.sectionId"
          :display-required="displayRequired"
          :display-include-as-segment="displayIncludeAsSegment"
          :widget-type-options="widgetTypeOptions.options"
        ></add-question>
      </div>
      <div class="row no-gutters">
        <div class="col text-center mb-3">
          <button type="submit" class="btn btn-primary" v-on:click="mode = 'add-question'">Add Question</button>
        </div>
      </div>
    </div>
    <div v-show="isEditMode" class="m-3">
      <div class="form-row form-group">
        <label for="sectionName" class="col-2">Name</label>
        <input v-model="sectionName" id="sectionName" name="sectionName" type="text" class="col-10" />
      </div>
      <div class="form-row form-group">
        <label for="sectionHeader" class="col-2">Header</label>
        <input v-model="sectionHeader" id="sectionHeader" name="sectionHeader" type="text" class="col-10" />
      </div>
      <div class="form-row form-group">
        <label for="sectionDescription" class="align-top col-2">Description</label>
        <textarea class="col-10" id="sectionDescription" name="sectionDescription" v-model="sectionDescription" rows="5"> </textarea>
      </div>
      <div class="form-row justify-content-end" role="group">
        <button type="submit" class="btn btn-primary mr-2" v-on:click="this.saveSection">Save Section</button>
        <button class="btn btn-primary" v-on:click="mode = 'view'">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import Question from "../components/Question.vue";
import SurveyField from "../components/SurveyField.vue";
import AddQuestion from "../components/AddQuestion.vue";

import SectionMixins from "../mixins/SectionMixins.vue";

import jQuery from "jquery";
import $ from "jquery";
import AdminService from "../services/admin.service";
import SectionService from "../services/section.service";

export default {
  name: "SurveySection",
  mixins: [SectionMixins],
  components: {
    Question,
    AddQuestion,
    SurveyField,
  },
  data() {
    return {
      mode: "view",
      widgetTypeOptions: {},
      maximumNumberOfChartSegments: 9,
      totalNumberOfChartSegments: 0,
      randomizeQuestionFieldName: "",
    };
  },
  props: {
    surveyId: {
      type: Number,
      default: 0,
    },
    surveyName: {
      type: String,
      default: "",
    },
    surveyContentSections: {
      type: Array,
      default: function () {
        return [];
      },
    },
    surveyTemplateObject: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initialMode: {
      type: String,
      default: "view",
    },
  },
  computed: {
    primaryButtonText: function () {
      if (this.mode == "edit") {
        return "Save Section";
      } else {
        return "Edit Section";
      }
    },
    activeQuestions: function () {
      return this.questions.filter((entry) => entry.active == "Y");
    },
    displayRequired: function () {
      return this.sectionObject.sectionType.name == "Segmentation Information" || this.sectionObject.sectionType.name == "Personal Information";
    },
    displayIncludeAsSegment: function () {
      return this.sectionObject.sectionType.name == "Segmentation Information";
    },
    isEditMode: function () {
      return this.mode == "edit";
    },
    displayHeader: function () {
      return !jQuery.isEmptyObject(this.sectionHeader);
    },
    isAddQuestionMode: function () {
      return this.mode == "add-question";
    },
    displayRandomizeEmotionalQuestions: function () {
      return this.sectionTypeName === "Emotional Needs";
    },
    displayRandomizeFunctionalQuestions: function () {
      return this.sectionTypeName === "Functional Needs";
    },
    disableEditMode: function () {
      return (
        (this.sectionName != "Actual Functional Needs" && this.sectionTypeName == "Functional Needs") ||
        (this.sectionName != "Actual Emotional Needs" && this.sectionTypeName == "Emotional Needs")
      );
    },
  },
  methods: {
    saveSectionCallback: function (response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        $.extend(this.section, response.data.data);
        this.mode = "view";
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async saveSection() {
      SectionService.saveSection(JSON.stringify(this.section), this.saveSectionCallback, this);
    },
    setTotalNumberOfChartSegments() {
      if (this.displayIncludeAsSegment) {
        let numberOfChartSegments = 0;
        this.activeQuestions.forEach(function (question) {
          if (question.includeAsSegment == "Y") {
            numberOfChartSegments++;
          }
        });
        this.totalNumberOfChartSegments = numberOfChartSegments;
      }
    },
  },
  created() {
    this.widgetTypeOptions = JSON.parse(this.surveyTemplateObject.configuration).widgets;
    this.randomizeQuestionFieldName = this.randomizeFieldName;
  },
  mounted() {
    this.setTotalNumberOfChartSegments();
    this.mode = this.initialMode;
  },
};
</script>
