import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import SurveyService from "./survey.service";
import UserService from "./user.service";
import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_CLIENT_URL = AdminService.getBaseUrl() + "/api/v1/client";

class ClientService {
  saveClient(client) {
    localStorage.setItem("client", JSON.stringify(client));
  }

  getClient() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client"));
    } else {
      return {};
    }
  }

  getClientId() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).clientId;
    } else {
      return 0;
    }
  }
  getClientName() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).name;
    } else {
      return "";
    }
  }
  getSurveyCodes() {
    if (localStorage.getItem("client") != null) {
      return JSON.parse(localStorage.getItem("client")).surveyCodes;
    } else {
      return "";
    }
  }

  async changeClient(baseUrl, clientId, callback, chartApp) {
    let userId = UserService.getUserId();
    if (typeof clientId != "undefined" && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("userId", userId);
      await axios
        .post(API_CLIENT_URL + "/get-client", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.changeClient", error, "survey-designer", chartApp);
        });
    }
  }

  clearCache() {
    localStorage.removeItem("get-all-clients-with-hierarchy");
  }
  async getClientList(callback, chartApp) {
    await axios
      .get(API_CLIENT_URL + "/get-client-list", { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.getClientList", error, "admin", chartApp);
      });
  }
  async getAllClients(callback) {
    if (localStorage.getItem("get-all-clients-with-hierarchy") != null) {
      callback(JSON.parse(localStorage.getItem("get-all-clients-with-hierarchy")));
    } else {
      let userId = UserService.getUserId();
      if (typeof userId != "undefined") {
        const params = new URLSearchParams();
        params.append("userId", userId);
        params.append("userTypeId", UserService.getUserTypeId());

        await axios
          .post(API_CLIENT_URL + "/get-all-clients-with-hierarchy", params, { headers: authHeader() })
          .then((response) => {
            localStorage.setItem("get-all-clients-with-hierarchy", JSON.stringify(response.data));
            callback(response.data);
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  }
  async getCompetitors(callback, clientId) {
    const params = new URLSearchParams();
    params.append("client_id", clientId);
    params.append("survey_id", SurveyService.getSurveyId());
    params.append("userId", UserService.getUserId());
    await axios
      .post(AdminService.getBaseUrl() + "/api/v1/chart/get-my-competitors", params, { headers: authHeader() })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        throw error;
      });
  }
  async deleteClientCompetitor(clientCompetitorsId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCompetitorsId", clientCompetitorsId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_URL + "/delete-competitor", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.deleteClientCompetitor", error, "survey-designer", chartApp);
      });
  }

  async updateCompetitorColor(clientCompetitorsId, color, surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCompetitorsId", clientCompetitorsId);
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    params.append("color", color);
    await axios
      .post(API_CLIENT_URL + "/update-competitor-color", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.updateCompetitorColor", error, "survey-designer", chartApp);
      });
  }

  async saveClientCompetitor(competitor, surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCompetitor", JSON.stringify(competitor));
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    await axios
      .post(API_CLIENT_URL + "/save-competitor", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientCompetitor", error, "survey-designer", chartApp);
      });
  }
  async getClientTimePeriods(callback, chartApp) {
    let clientId = this.getClientId();
    let userId = UserService.getUserId();
    if (clientId != 0 && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("userId", userId);
      await axios
        .post(API_CLIENT_URL + "/get-client-time-periods", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getClientTimePeriods", error, "survey-designer", chartApp);
        });
    }
  }
  async getPredictiveAnalytics(metricGroupId, period, callback, chartApp) {
    let clientId = this.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("surveyId", SurveyService.getSurveyId());
      params.append("userId", UserService.getUserId());
      params.append("period", period);
      params.append("metricGroupId", metricGroupId);
      await axios
        .post(API_CLIENT_URL + "/get-predictive-analytics", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getPredictiveAnalytics", error, "survey-designer", chartApp);
        });
    }
  }
  async deleteClient(clientId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientId", clientId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_URL + "/delete-client", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.deleteClient", error, "survey-designer", chartApp);
      });
  }
  async copyClient(clientId, numberOfCopies, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientId", clientId);
    params.append("userId", UserService.getUserId());
    params.append("numberOfCopies", numberOfCopies);
    await axios
      .post(API_CLIENT_URL + "/copy-client", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.copyClient", error, "survey-designer", chartApp);
      });
  }
  async saveClientToDatabase(mode, newSurveyName, formClientObject, clientCompetitors, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("mode", mode);
    params.append("surveyName", newSurveyName);
    params.append("clientName", formClientObject.name);
    params.append("clientId", formClientObject.clientId);
    params.append("sectorId", formClientObject.sectorId);
    params.append("isParent", formClientObject.isParent);
    params.append("color", formClientObject.color);
    params.append("parentClientId", formClientObject.parentClientId);
    params.append("subscriptionEndDate", formClientObject.subscriptionEndDate);
    params.append("clientCompetitors", JSON.stringify(clientCompetitors));
    await axios
      .post(API_CLIENT_URL + "/save-client", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientToDatabase", error, "admin", chartApp);
      });
  }
  async getClientFromDatabase(initialClientId, callback, chartApp) {
    let userId = UserService.getUserId();
    if (typeof initialClientId != "undefined" && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("clientId", initialClientId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_URL + "/get-client", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientService.getClientFromDatabase", error, "admin", chartApp);
        });
    }
  }
  async getAllParentClients(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_URL + "/get-parent-clients", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.getAllParentClients", error, "admin", chartApp);
      });
  }
  async isClientHierarchy() {}
  async saveClientHierarchy(clientId, stringClientHierarchyList, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientId", clientId);
    params.append("userId", UserService.getUserId());
    params.append("stringClientHierarchyList", stringClientHierarchyList);
    await axios
      .post(API_CLIENT_URL + "/save-client-hierarchy", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.saveClientHierarchy", error, "admin", chartApp);
      });
  }

  async getClientHierarchy(initialClientId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientId", initialClientId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_URL + "/get-client-hierarchy", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientService.getClientFromDatabase", error, "admin", chartApp);
      });
  }
  getTableColumnData() {
    return [
      {
        data: "clientId",
        createdCell: function (td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "5rem",
      },
      { data: "name", name: "name", width: "15rem" },
      { data: "parentClientName", name: "parentClientName", width: "15rem" },
      { data: "isParent", name: "isParent", width: "5rem" },
      {
        data: "active",
        name: "status",
        width: "5rem",
        render: function (data, type, row) {
          var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        },
      },
      {
        data: "subscriptionEndDate",
        type: "date",
        name: "subscriptionEndDate",
        width: "5rem",
        render: function (data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
        },
      },
      {
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        width: "5rem",
        render: function (data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
        },
      },
      {
        data: "dateModified",
        type: "date",
        name: "dateModified",
        width: "5rem",
        render: function (data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
        },
      },
    ];
  }
}

export default new ClientService();
