<template>
  <div class="login col-md-12">
    <div class="card card-container">
      <img id="profile-img" src="../assets/img/wrist-and-hand-center-tampa-logo-1.png" class="profile-img-card" />
      <notifications group="auth" position="top center" />

      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">Username</label>
          <input v-model="user.username" type="text" class="form-control" name="username" />
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <input v-model="user.password" type="password" class="form-control" name="password" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="this.$root.getLoading()">
            <span class="pr-2">Login</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
        <div class="form-group text-center">
          <a class="" href="#" @click="$modal.show('forgot-password')">Forgot your password?</a>
        </div>
      </form>
    </div>
    <forgot-password-modal :width="350" :height="250"></forgot-password-modal>
  </div>
</template>

<script>
import User from "../models/user";
import AdminService from "../services/admin.service";
import UserService from "../services/user.service";
import ForgotPasswordModal from "../modals/ForgotPasswordModal.vue";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
    };
  },
  components: {
    ForgotPasswordModal,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  props: {
    forceLogout: {
      type: Boolean,
      default: false,
    },
    initialMessage: {
      type: String,
      default: "",
    },
  },
  created() {},
  mounted() {
    if (this.initialMessage != "") {
      this.$notify(AdminService.displayInfoMessage({ text: this.initialMessage, group: "auth" }));
    }
    if (this.forceLogout) {
      this.handleLogout();
    } else if (this.loggedIn) {
      if (this.$router.currentRoute.name != "form-responses") {        
        this.$router.push("/form-responses/form-responses");
      }
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    handleLogin() {
      this.$root.setLoading(true);
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$ga.set("userId", UserService.getUserId());
            if (this.$router.currentRoute.name != "form-responses" || this.$router.currentRoute.name != "dashboard") {        
            //  this.$router.push("/form-responses/form-responses");
            }
          },
          (error) => {
            this.$root.setLoading(false);
            let returnMessage = (error.response && error.response.data) || error.message || error.toString();
            this.$notify(AdminService.displayErrorMessage({ text: returnMessage.message, group: "auth" }));
            this.$log.error(returnMessage.message);
          }
        );
      }
    },
  },
};
</script>
