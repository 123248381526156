<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import StudyDesignMenu from "../views/menu/StudyDesignMenu.vue";
import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";

import authHeader from "../services/auth-header";
import ClientService from "../services/client.service";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import UserService from "../services/user.service";
import SurveyService from "../services/survey.service";

export default {
  name: "StudyDesignMixins",
  mixins: [ContentMixins, ViewMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    StudyDesignMenu,
  },
  data() {
    return {
      disableButton: false,
      doesSurveyUrlWork: true,
      survey: {},
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl(),
    },
  },
  created() {},
  methods: {
    displayDefaultSuccessMessage: function () {
      this.$notify(AdminService.displaySuccessMessage({ text: "Configuration saved successfully" }));
    },
    async saveBuildRequired(buildRequired) {
      const params = new URLSearchParams();
      params.append("buildRequired", buildRequired);
      params.append("surveyId", this.survey.surveyId);
      params.append("clientId", this.clientId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(this.baseUrl + "/api/v1/survey-design/save-build-required", params, { headers: authHeader() })
        .then((response) => this.saveBuildRequiredCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("TheIdeal.saveBuildRequired", error, "survey-designer", this);
        });
    },
    saveBuildRequiredCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.error = false;
        this.survey.buildRequired = response.data.data;
      } else {
        this.error = true;
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async saveSurvey() {
      if (this.survey.buildRequired == "Y") {
        this.$root.setLoading(true);
        this.resetData();
        this.$notify(AdminService.displayInfoMessage({ text: "Saving survey setup please wait.....", duration: -1 }));
        const params = new URLSearchParams();
        params.append("surveyCode", this.surveyCode);
        params.append("userId", UserService.getUserId());
        await axios
          .post(this.baseUrl + "/api/v1/survey-design/save-survey-design", params, { headers: authHeader() })
          .then((response) => this.saveSurveyCallBack(response))
          .catch((error) => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("StudyDesignMixins.saveSurvey", error, "survey-designer", this);
          });
      } else {
        this.goToSurveyDesign();
      }
    },
    saveSurveyCallBack(response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data;
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        this.goToSurveyDesign();
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    resetData() {
      this.setDisplayView(false);
      this.survey = {};
      if (typeof this.competitors != undefined) {
        this.competitors = ["", "", "", "", ""];
      }
    },
    async setupSurveyDesignerCallBack(response) {
      this.$notify({ group: "admin", clean: true });
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data.survey;
        if (typeof this.competitors != undefined) {
          this.competitors = response.data.data.competitors;
        }
        if (typeof this.numberOfCompletedSurveys != undefined) {
          this.numberOfCompletedSurveys = response.data.data.numberOfCompletedSurveys;
        }
        this.doesSurveyUrlWork = await SurveyService.doesSurveyUrlWork(this.survey);

        this.postLoadData();
      } else {
        this.goToCreateSurvey();
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async setupSurveyDesigner() {
      this.resetData();
      this.$root.setLoading(true);
      this.$notify({ group: "admin", clean: true });
      this.$notify(AdminService.displayInfoMessage({ text: "Loading survey setup please wait....." }));
      const params = new URLSearchParams();
      params.append("surveyCode", this.surveyCode);
      params.append("userId", UserService.getUserId());

      await axios
        .post(this.baseUrl + "/api/v1/survey-design/get-existing-survey-design", params, { headers: authHeader() })
        .then((response) => this.setupSurveyDesignerCallBack(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("StudyDesignMixins.setupSurveyDesigner", error, "survey-designer", this);
        });
    },
    saveSurveyField: function (result) {
      var match = this.surveyFields.find((x) => Number(x.surveyFieldId) === Number(result.surveyFieldId));
      if (typeof match !== "undefined") {
        this.surveyFields.splice($.inArray(match, this.surveyFields), 1);
      }
      this.surveyFields.push(result);
      if (result.buildSurvey) {
        this.saveBuildRequired("Y");
      }
    },
    saveSurveyFieldWithSuccessMessage: function (result) {
      this.saveSurveyField(result);
      this.displayDefaultSuccessMessage();
    },
    getSection(sectionName) {
      if (this.sections) {
        return this.sections.find((x) => x.name === sectionName);
      } else {
        return {};
      }
    },
    getSurveyField(surveyFieldName) {
      if (this.surveyFields) {
        return this.surveyFields.find((x) => x.surveyFieldName == surveyFieldName);
      } else {
        return {};
      }
    },
    goToCreateSurvey: function () {
      if (this.$router.currentRoute.name != "create-survey") {
        this.$router.replace({ path: "/study-design/create-survey" });
      }
    },
    goToRespondentDownload: function () {
      if (this.$router.currentRoute.name != "respondent-download") {
        this.$router.replace({ path: "/study-design/respondent-download" });
      }
    },
    goToIncludeSection: function () {
      if (this.$router.currentRoute.name != "include-sections") {
        this.$router.replace({ path: "/study-design/include-sections" });
      } else {
        this.$notify(AdminService.displayInfoMessage({ text: " Please click the Next button to continue with the survey setup" }));
        this.setDisplayView(true);
      }
    },
    goToDefaultFields: function () {
      if (this.$router.currentRoute.name != "default-fields") {
        this.$router.replace({ path: "/study-design/default-fields" });
      }
    },
    goToSurveyPreview: function () {
      if (this.hasSurveyBeenBuilt || this.isSurveyEmpty) {
        if (this.$router.currentRoute.name != "form-preview") {
          this.$router.replace({ path: "/form-design/form-preview" });
        }
      } else {
        this.$notify(AdminService.displayInfoMessage({ text: "You are being redirected to the Default Fields page. Please click the Next button to generate the survey" }));
        let vm = this;
        setTimeout(function () {
          vm.goToDefaultFields();
        }, 1000);
      }
    },
    goToSurveyDesign: function () {
      if (this.$router.currentRoute.name != "form-design") {
        this.$router.replace({ path: "/form-design/form-design" });
      }
    },
    goToManageInvitations: function () {
      if (this.hasSurveyBeenBuilt || this.isSurveyEmpty) {
        if (this.$router.currentRoute.name != "manage-invitations") {
          this.$router.replace({ path: "/study-design/manage-invitations" });
        }
      } else {
        this.$notify(AdminService.displayInfoMessage({ text: "You are being redirected to the Default Fields page. Please click the Next button to generate the survey" }));
        let vm = this;
        setTimeout(function () {
          vm.goToDefaultFields();
        }, 1000);
      }
    },
    loadData: function () {
      this.setupSurveyDesigner();
    },
  },
  watch: {
    surveyCode: function () {
      this.loadData();
    },
    displaySurveyWidget: function () {
      return this.$parent.displaySurveyWidget;
    },
  },
  mounted() {
    if (this.clientId != 0) {
      this.loadData();
    }
    this.$parent.displayShowMenuIcon = true;
  },
  computed: {
    baseInterviewUrl: function () {
      return typeof process.env.VUE_APP_INTERVIEW_URL != "undefined" ? process.env.VUE_APP_INTERVIEW_URL : "https://interview.wratings.com";
    },
    liveSurveyUrl: function () {
      if (this.survey.url != "" && this.baseInterviewUrl != "") {
        return this.baseInterviewUrl + "/" + this.survey.clientId + "/s/" + this.survey.url;
      } else {
        return "";
      }
    },
    clientId: function () {
      if (typeof this.$parent.$data.clientId != "undefined") {
        return this.$parent.$data.clientId;
      } else {
        return 0;
      }
    },
    clientName: function () {
      if (typeof this.$parent.clientObject.name != "undefined") {
        return this.$parent.clientObject.name;
      } else {
        return "";
      }
    },
    surveyCode: function () {
      return this.$parent.$data.surveyCode;
    },
    surveyFields: function () {
      if (this.survey.surveyFields) {
        return this.survey.surveyFields;
      } else {
        return [];
      }
    },
    sections: function () {
      if (this.survey.sections) {
        return this.survey.sections;
      } else {
        return [];
      }
    },
    isSurveyEmpty: function () {
      return jQuery.isEmptyObject(this.survey);
    },
    hasSurveyBeenBuilt: function () {
      return this.survey.buildRequired == "N";
    },
    isAdvancedUser: function () {
      return this.$parent.isAdvancedUser || this.$parent.isAdmin;
    },
    isBasicUser: function () {
      return this.$parent.isBasicUser;
    },
    buttonText: function () {
      return this.survey.buildRequired == "N" ? "Save" : "Next";
    },
  },
};
</script>
