<template>
  <div v-if="isViewModeQuestion" class="form-inline w-100 survey-question question-body">
    <div v-if="selectedWidgetType.name == 'Slider'" class="col-9">{{ questionIndex + 1 }}. {{ questionLabel.toString() }}</div>
    <div v-else class="col-8">{{ questionIndex + 1 }}. {{ questionLabel }}</div>
    <div v-if="!disableEditMode" class="question-actions col-4">
      <modal
        :modal-id="deleteConfirmationTitle"
        :modal-title="'Delete Confirmation'"
        :modal-content="'Are you sure you want delete ' + questionObject.label + ' ?'"
        :modal-result-id="questionObject.questionId"
        :include-confirmation="true"
        @confirm="deleteQuestion"
      />
      <button
        v-show="!notEditable && !isCompetitorCompanyCodeQuestion"
        type="submit"
        class="btn btn-primary float-right ml-2"
        @click="displayConfirmation(deleteConfirmationTitle)"
      >
        <span>Delete</span>
      </button>
      <button v-if="!notEditable" class="btn btn-primary float-right" type="submit" @click="mode = 'edit'">Edit</button>
      <button v-if="notEditable" class="btn btn-primary float-right" type="button" @click="showRedirectModal">Edit</button>
    </div>
    <div v-if="disableEditMode" class="col-3 text-right">
      <button class="btn btn-primary ml-4" type="button" @click="showRedirectModal">Edit</button>
    </div>
    <div v-show="!notEditable" class="w-100 pl-2">
      <input v-if="isTextBox || isEmail || isPhoneNumber" type="text" disabled />
      <select v-if="isDropdown" class="w-25" disabled />
      <input v-if="isCheckbox" type="checkbox" disabled />
      <input v-if="isRadio" type="radio" disabled />
      <textarea v-if="isTextArea || isContent" />
      <img v-if="isSlider" src="../assets/img/slider.png" />
    </div>
  </div>
  <div v-else class="edit-question-container p-1">
    <div class="row m-3">
      <div class="w-100 form-row align-items-center p-1">
        <div class="mr-1">Q{{ questionIndex }}</div>
        <div class="form-group col-8">
          <div v-if="selectedWidgetType.name == 'Slider'" class="form-inline">
            <div class="form-inline w-50">
              <label for="leftLabel" class="mr-1">Left Label</label>
              <input v-model="questionLabel[0]" :id="questionId" :name="questionId" type="text" class="form-control w-75" />
            </div>
            <div class="form-inline w-50">
              <label for="rightLabel" class="mr-1">Right Label</label>
              <input v-model="questionLabel[1]" :id="questionId" :name="questionId" type="text" class="form-control w-75" />
            </div>
          </div>
          <input v-else v-model="questionLabel" :id="questionId" :name="questionId" type="text" class="form-control w-100" />
        </div>
        <div class="form-group col-3">
          <select v-if="!notEditable" id="widgetType" v-model="questionWidgetTypeId" name="widgetType" class="custom-select" @change="setSelectedWidgetType">
            <option value="">Please Select Widget Type</option>
            <option
              v-for="(option, idxWidgetTypeOptions) in this.widgetTypeOptionsToDisplay"
              v-bind:value="option.value"
              v-bind:key="idxWidgetTypeOptions"
              v-bind:selected="widgetTypeId == option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <div v-if="notEditable">You are not able to update the field type for this section</div>
        </div>
      </div>
      <div v-if="displayPlaceholder" class="form-row w-100">
        <div class="form-group col-12">
          <question-placeholder :initial-placeholder="widgetConfiguration.placeholder" @save="setWidgetConfigurationPlaceholder"> </question-placeholder>
        </div>
      </div>
      <div class="form-row w-100">
        <div v-show="displayRequired" class="form-group col-lg-2 col-sm-12">
          <label for="required" class="control-label pr-1">Required</label>
          <input type="checkbox" id="required" name="required" v-model="questionRequired" @click="checkQuestionRequired($event)" true-value="Y" false-value="N" />
        </div>
        <div v-show="displayIncludeAsSegment" class="form-group col-lg-3 col-sm-12">
          <label for="segment" class="control-label pr-1">Include as segment in charts</label>
          <input type="checkbox" id="segment" name="segment" v-model="includeAsSegment" true-value="Y" false-value="N" @click="checkSegmentLimit($event)" />
        </div>
        <div v-show="includeAsSegment == 'Y'" class="form-group col-lg-3 col-sm-12" style="margin-top: -0.3rem">
          <div class="form-inline">
            <label for="segmentLabel" class="control-label pr-1 text-danger">Label for chart</label>
            <input v-model="segmentLabel" id="segmentLabel" name="segmentLabel" type="text" class="form-control" />
          </div>
        </div>
      </div>
      <div v-if="!notEditable" class="w-100 form-row" style="border-top: 1px solid #d0d2d3">
        <question-content
          v-if="selectedWidgetType !== undefined && selectedWidgetType.name == 'Content Section'"
          :survey-name="$parent.$parent.surveyName"
          :initial-content-id="Number(widgetConfiguration.contentId)"
          :content-sections="$parent.$parent.surveyContentSections"
          @save="setWidgetConfigurationContentId"
          @reload="reloadContentSections"
        >
        </question-content>
        <div v-if="selectedWidgetType.name == 'Slider'" class="form-inline p-1 mt-2">
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Minimum Value</label>
            <input v-model="widgetConfiguration.min" id="minimumValue" name="minimumValue" type="text" class="form-control w-50" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Maximum Value</label>
            <input v-model="widgetConfiguration.max" id="maximumValue" name="maximumValue" type="text" class="form-control w-50" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Increment Value</label>
            <input v-model="widgetConfiguration.step" id="incrementValue" name="incrementValue" type="text" class="form-control w-50" />
          </div>
          <div class="form-group w-25">
            <label for="minimumValue" class="mr-1">Default Value</label>
            <input v-model="widgetConfiguration.value" :id="'defaultValue_'+questionId" name="defaultValue" type="text" class="form-control w-50" />
          </div>
        </div>
        <div v-show="isCompetitorCompanyCodeQuestion" class="w-100 form-row">
          <div class="mr-1 text-danger text-center col-12">You will be need to go to the Constants screen to add/remove competitors</div>
        </div>
        <question-option
          v-for="(widgetConfigurationOption, idxWidgetConfiguration) in this.widgetConfiguration.options"
          class="w-100 form-row"
          :key="widgetConfigurationOption.value"
          :new-question-option-index="idxWidgetConfiguration"
          :option-object="widgetConfigurationOption"
          :is-editable="!isCompetitorCompanyCodeQuestion"
          @save="saveOption"
          @remove="removeOption"
        >
        </question-option>
      </div>
      <div class="w-100 form-row">
        <modal
          :modal-id="'Revert Changes'"
          :modal-title="'Cancel Confirmation'"
          :modal-content="'Are you sure you want revert your changes ?'"
          :modal-result-id="questionObject.questionId"
          :include-confirmation="true"
          @confirm="resetQuestion"
        />
        <div class="col p-1">
          <button class="btn btn-primary float-right ml-2" :disabled="disableCancelButton" @click="displayConfirmation('Revert Changes')">Cancel</button>
          <button type="submit" class="btn btn-primary float-right ml-2" @click="saveQuestion()" :disabled="disableSaveButton">
            <span>Save Question</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";

import QuestionMixins from "../mixins/QuestionMixins.vue";
import QuestionContent from "../components/QuestionContent.vue";
import QuestionPlaceholder from "../components/QuestionPlaceholder.vue";

import "jquery-ui";
import QuestionOption from "../components/QuestionOption.vue";
import ContentMixins from "../mixins/ContentMixins.vue";
import AdminService from "../services/admin.service";
import QuestionService from "../services/question.service";

export default {
  name: "QuestionDetails",
  mixins: [QuestionMixins, ContentMixins],
  props: {},
  components: {
    QuestionOption,
    QuestionContent,
    QuestionPlaceholder,
  },
  data() {
    return {
      mode: "view",
      questionWidgetTypeId: "",
      disableSaveButton: false,
      disableCancelButton: false,
      question: {},
      selectedWidgetType: {},
      widgetConfiguration: {},
    };
  },
  methods: {
    disableButtons: function () {
      this.disableSaveButton = true;
      this.disableCancelButton = true;
    },
    enableButtons: function () {
      this.disableSaveButton = false;
      this.disableCancelButton = false;
    },
    reloadContentSections: function () {
      this.$parent.$parent.$emit("reload");
    },
    setWidgetConfigurationContentId: function (result) {
      this.widgetConfiguration.contentId = result.contentId;
      this.questionLabel = "Content - " + result.name;
    },
    checkQuestionRequired: function (e) {
      if (e.target.checked) {
        this.questionRequired == "Y";
      } else {
        this.questionRequired == "N";
      }
    },
    setWidgetConfigurationPlaceholder: function (result) {
      this.widgetConfiguration.placeholder = result;
    },
    resetQuestion() {
      this.mode = "view";
      this.setSelectedWidgetType();
      this.initializeQuestion();
    },
    displayConfirmation(modalId) {
      this.$modal.show(modalId);
    },
    showRedirectModal() {
      if (this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs") {
        this.$modal.show("navigate-to-needs");
      } else if (this.sectionTypeName == "Pricing Power") {
        this.$modal.show("navigate-to-ideal");
      }
    },
    saveOption: function (result) {
      var option = this.widgetConfiguration.options.find((x) => x.value == result.value);
      if (option !== undefined) {
        option.name = result.name;
      }
    },
    removeOption: function (result) {
      var option = this.widgetConfiguration.options.find((x) => x.value == result.value);
      if (option !== undefined) {
        this.widgetConfiguration.options.splice($.inArray(option, this.widgetConfiguration.options), 1);
      }
    },
    async deleteQuestion(resultId) {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Deleting Question....Please wait" }));
      if (!this.initialLoad) {
        QuestionService.deleteQuestion(resultId, this.deleteQuestionCallback, this);
      }
    },
    deleteQuestionCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$emit("remove", this.question);
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    checkSegmentLimit(event) {
      if (event.target.checked) {
        if (1 + this.$parent.$parent.totalNumberOfChartSegments > this.$parent.$parent.maximumNumberOfChartSegments) {
          this.$notify(
            AdminService.displayErrorMessage({
              text: "The maximum number of chart segments have been selected. If you want to include any additional selections you must deselect an existing question.",
              duration: -1,
            })
          );
          event.preventDefault();
          this.includeAsSegment = "N";
          event.target.checked = "off";
        } else {
          this.includeAsSegment == "Y";
        }
      } else {
        this.includeAsSegment == "N";
      }
    },
    async saveQuestion() {
      if (
        this.includeAsSegment == "Y" &&
        this.selectedWidgetType !== undefined &&
        this.selectedWidgetType.name != "Dropdown" &&
        this.selectedWidgetType.name != "Radio Button Group" &&
        this.selectedWidgetType.name != "Checkbox Group"
      ) {
        this.$notify(
          AdminService.displayErrorMessage({ text: "You must have a field type of dropdown, radio button, or checkbox if the question is marked as a segment.", duration: -1 })
        );
        return;
      }
      if (this.includeAsSegment == "Y" && this.segmentLabel == "") {
        this.$notify(AdminService.displayErrorMessage({ text: "Please populate the Label for chart field.", duration: -1 }));
        return;
      } else this.$notify({ group: "admin", clean: true });
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Saving Question....Please wait" }));

      var result = {
        label: this.questionLabel,
        required: this.questionRequired,
        questionId: this.question.questionId,
        widgetTypeId: this.questionWidgetTypeId,
        widgetConfiguration: JSON.stringify(this.widgetConfiguration),
        isCompetitorCompanyCodeQuestion: this.isCompetitorCompanyCodeQuestion,
        includeAsSegment: this.includeAsSegment,
        segmentLabel: this.segmentLabel,
      };
      if (!this.initialLoad) {
        QuestionService.saveQuestion(result, this.saveQuestionCallback, this);
      }
    },
    saveQuestionCallback(response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        $.extend(this.question, response.data.data.question);
        $.extend(this.$parent.question, response.data.data.question);
        this.$parent.$parent.setTotalNumberOfChartSegments();
        this.mode = "view";
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    setSelectedWidgetType() {
      this.selectedWidgetType = this.getWidgetType(this.questionWidgetTypeId);
      // Dont do anything if it is the competitor code
      if (this.isCompetitorCompanyCodeQuestion) {
        return;
      }
      if (!jQuery.isEmptyObject(this.selectedWidgetType)) {
        // Set it to the original
        if (this.selectedWidgetType.name == this.questionObject.widgetType.description) {
          if (this.questionObject.widgetConfiguration != "") {
            this.widgetConfiguration = JSON.parse(this.questionObject.widgetConfiguration);
          } else {
            this.widgetConfiguration = {};
          }
        } else if (this.selectedWidgetType.name == "Dropdown") {
          this.widgetConfiguration.options = [
            { name: "", value: "-1" },
            { name: "PLEASE SELECT", value: "0", selected: "selected" },
            { name: " ", value: "1" },
          ];
        } else if (this.selectedWidgetType.name == "Checkbox Group" || this.selectedWidgetType.name == "Radio Button Group") {
          this.widgetConfiguration.options = [{ name: " ", value: "1" }];
        } else {
          this.widgetConfiguration.options = [];
        }
        if (this.selectedWidgetType.name == "Slider") {
          if (jQuery.isEmptyObject(this.Configuration)) {
            this.widgetConfiguration = { min: 6, max: 605, step: 100, value: 308 };
          }
        }
      }
    },
    getWidgetType(widgetId) {
      if (this.widgetTypeOptionsToDisplay) {
        return this.widgetTypeOptionsToDisplay.find((x) => x.value == widgetId);
      } else {
        return {};
      }
    },
  },
  created() {
    $.extend(this.question, this.questionObject);
    if (this.isSlider) {
      this.questionLabel = this.questionObject.label.split(",");
    }
  },
  mounted() {
    this.initialLoad = false;
    this.questionWidgetTypeId = this.questionObject.widgetType.widgetTypeId;
    if (this.questionObject.widgetConfiguration !== "") {
      this.widgetConfiguration = JSON.parse(this.questionObject.widgetConfiguration);
    } else {
      this.widgetConfiguration = {};
    }
    this.setSelectedWidgetType();
  },
  watch: {},
  computed: {
    deleteConfirmationTitle: function () {
      return "Confirmation " + this.questionObject.label;
    },
    displayPlaceholder: function () {
      return this.isPhoneNumber || this.isTextBox || this.isEmail;
    },
    isEditMode: function () {
      return this.mode == "edit";
    },
    isViewModeQuestion: function () {
      return this.mode == "view";
    },
    notEditable: function () {
      return this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs" || this.sectionTypeName == "Pricing Power";
    },
    isPermissionQuestion: function () {
      let isPermissionQuestion = false;
      if (typeof this.questionObject.questionType != "undefined" && this.questionObject.questionType != null) {
        isPermissionQuestion = this.questionObject.questionType.questionType == "Net Promoters Score" && this.questionObject.questionType.name == "NPS Permission";
      }
      return isPermissionQuestion;
    },
    isCompetitorCompanyCodeQuestion: function () {
      let isCompetitorCompanyCodeQuestion = false;
      if (typeof this.questionObject.questionType != "undefined" && this.questionObject.questionType != null) {
        isCompetitorCompanyCodeQuestion =
          this.questionObject.questionType.questionType == "Net Promoters Score" && this.questionObject.questionType.name == "Competitor Company Code";
      }
      return isCompetitorCompanyCodeQuestion;
    },
    widgetTypeId: function () {
      return this.question.widgetType.widgetTypeId;
    },
    widgetTypeOptionsToDisplay() {
      if (this.isCompetitorCompanyCodeQuestion) {
        let returnArray = [];
        let dropdownWidgetType = this.widgetTypeOptions.find((x) => x.name == "Dropdown");
        let radioButtonWidgetType = this.widgetTypeOptions.find((x) => x.name == "Radio Button Group");
        returnArray.push(dropdownWidgetType);
        returnArray.push(radioButtonWidgetType);
        return returnArray;
      } else if (this.includeAsSegment == "Y") {
        let returnArray = [];
        let dropdownWidgetType = this.widgetTypeOptions.find((x) => x.name == "Dropdown");
        let radioButtonWidgetType = this.widgetTypeOptions.find((x) => x.name == "Radio Button Group");
        let checkboxWidgetType = this.widgetTypeOptions.find((x) => x.name == "Checkbox Group");
        returnArray.push(dropdownWidgetType);
        returnArray.push(radioButtonWidgetType);
        returnArray.push(checkboxWidgetType);
        return returnArray;
      }
      return this.widgetTypeOptions;
    },
  },
};
</script>
