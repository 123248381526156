import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import ClientService from "./client.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;
const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/survey-summary";
const GET_SUMMARY_DATA_BY_SURVEY_ID = "Loading survey respondent data.....";

class SurveySummaryService {
  async getDownloadSurveyDataUrlMultiple(dataSet, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientId", ClientService.getClientId());
    params.append("userId", UserService.getUserId());
    params.append("dataSetString", JSON.stringify(dataSet));
    let url = API_URL + "/download-survey-data-by-time-period";
    await axios({
      url: url,
      params: params,
      method: "POST",
      responseType: "blob",
    })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        chartApp.downloadItemError(error);
      });
  }

  async getSummaryDataByClientIdAndDate(startDate, endDate, callback, chatApp) {
    chatApp.$notify(AdminService.displayInfoMessage({ text: GET_SUMMARY_DATA_BY_SURVEY_ID }, { duration: -1 }));
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("startDate", startDate);
      params.append("endDate", endDate);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_URL + "/get-survey-aggregate-data-by-survey-id-and-date", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getSummaryDataBySurveyId", error, "survey-designer", chatApp);
        });
    }
  }
  async getSummaryDataBySurveyId(callback, chatApp) {
    chatApp.$notify(AdminService.displayInfoMessage({ text: GET_SUMMARY_DATA_BY_SURVEY_ID }, { duration: -1 }));
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", SurveyService.getSurveyId());
      await axios
        .post(API_URL + "/get-survey-aggregate-data-by-survey-id", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getSummaryDataBySurveyId", error, "survey-designer", chatApp);
        });
    }
  }
  async getRanks(value, callback, chatApp) {
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("clientTimePeriodId", value);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", SurveyService.getSurveyId());
      await axios
        .post(API_URL + "/get-ranks-by-time-period", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getRanks", error, "survey-designer", chatApp);
        });
    }
  }

  async getNumberOfRespondents(value, callback, chatApp) {
    let clientTimePeriodId = value;
    let clientId = ClientService.getClientId();
    if (clientId != 0) {
      const params = new URLSearchParams();
      params.append("clientId", clientId);
      params.append("clientTimePeriodId", clientTimePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", SurveyService.getSurveyId());
      await axios
        .post(API_URL + "/get-number-of-respondents", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.getNumberOfRepondents", error, "survey-designer", chatApp);
        });
    }
  }

  async processPartialResponses(maxNumberOfPartialRecords, callback, chatApp) {
    let userId = UserService.getUserId();
    if (typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("userId", userId);
      params.append("maxNumberOfPartialRecords", maxNumberOfPartialRecords);
      await axios
        .post(API_URL + "/process-partial-respondents", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.processPartialResponses", error, "admin", chatApp);
        });
    }
  }
  async processPartialResponsesForSurvey(surveryId, maxNumberOfPartialRecords, callback, chatApp) {
    let userId = UserService.getUserId();
    if (surveryId != 0 && typeof userId != "undefined") {
      const params = new URLSearchParams();
      params.append("surveyId", surveryId);
      params.append("userId", userId);
      params.append("maxNumberOfPartialRecords", maxNumberOfPartialRecords);
      await axios
        .post(API_URL + "/process-partial-respondents", params, { headers: authHeader() })
        .then((response) => callback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveySummaryService.processPartialResponses", error, "admin", chatApp);
        });
    }
  }
  getRespondentDownloadTableColumnData() {
    return [
      {
        title: "Download",
        bVisible: true,
        data: "id",
        name: "id",
        width: "4rem",
        className: "select-checkbox text-center",
        render: function (data, type, full, meta) {
          return '<input type="checkbox" name="id[]" value="' + data.surveyId + '">';
        },
      },
      { title: "Form Name", data: "baseSurveyName", name: "baseSurveyName", width: "10rem" },
      { title: "Collection Dates", data: "surveyName", name: "surveyName", width: "10rem" },
      { title: "Total Opens", data: "totalSurveysSent", className: "text-center", name: "totalSurveysSent", width: "5rem" },
      { title: "Total Complete", data: "completedSurveyTotal", className: "text-center", name: "completedSurveyTotal", width: "5rem" },
      { title: "Total Partial", data: "incompletedSurveyTotal", className: "text-center", name: "incompletedSurveyTotal", width: "5rem" },
      {
        title: "Participation Rate",
        data: "completionRate",
        className: "text-center",
        name: "completionRate",
        width: "5rem",
        render: function (data, type, row) {
          return Number(data).toFixed(1) + " %";
        },
      },
      {
        title: "Average Time",
        data: "averageCompletionTime",
        className: "text-center",
        name: "averageCompletionTime",
        width: "7rem",
        render: function (data, type, row) {
          if (data > 120) {
            return moment.unix(data).utc().format("m[m :]s[s]");
          } else if (data >= 60 && data <= 120) {
            return moment.unix(data).utc().format("m[m :]s[s]");
          } else if (data > 0 && data < 60) {
            return moment.unix(data).utc().format("s[s]");
          } else {
            return "--";
          }
        },
      },
      { title: "", bVisible: false, data: "startDate", name: "startDate", width: "0rem" },
    ];
  }
  getSurveyStatusTableColumnData() {
    return [
      { title: "Client Name", data: "clientName", name: "clientName", width: "10rem" },
      { title: "Survey Name", data: "baseSurveyName", name: "baseSurveyName", width: "10rem" },
      { title: "Total Responses", data: "totalCompleteOrPartial", className: "text-center", name: "totalCompleteOrPartial", width: "5rem" },
      { title: "", bVisible: false, data: "startDate", name: "startDate", width: "0rem" },
    ];
  }
  printForm(title = "") {
    var imgWindow = window.open("", "_blank", "width=700,height=auto");
    var doc = imgWindow.document;
    var baseNode = $("#survey-taker-app").clone();
    doc.write("<html><head>");
    doc.write("<title>")
    doc.write(title);
    doc.write("</title>");
    doc.write("<link href='https://www.rayhack.com/assets/css/patient-form-print.css?v=4' rel='stylesheet' type='text/css'>");
    doc.write("</head><body>");
    doc.write('<div id="print-form">');
    doc.write(baseNode.html());
    doc.write("</div>");
    doc.write("</body></html>");

    doc.close();
    imgWindow.focus();
    setTimeout(function () {
      imgWindow.print();
    }, 700);
  }
}

export default new SurveySummaryService();
