<template>
  <div></div>
</template>
<script>
import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "ModalMixins",
  mixins: [ContentMixins, ViewMixins],
  components: {},
  data() {
    return {
      title: "",
      name: "",
    };
  },
  props: {
    width: {
      type: Number,
      default: isMobile ? 220 : 368,
    },
    height: {
      type: Number,
      default: 450,
    },
  },
  created() {},
  methods: {},
  watch: {},
  mounted() {},
  computed: {},
};
</script>
