class ValidatorService {
  passwordErrorMessage = "Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, no parts of your username";

  passwordCheck(value, element, param) {
    if (this.optional(element)) {
      return true;
    } else if (!/[A-Z]/.test(value)) {
      return false;
    } else if (!/[a-z]/.test(value)) {
      return false;
    } else if (!/[0-9]/.test(value)) {
      return false;
    }
    return true;
  }
}
export default new ValidatorService();
