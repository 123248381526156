<template>
  <div v-show="showMenu">
    <div class="d-flex md-stepper-horizontal">
      <div v-bind:class="[this.isActivePage(1) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToRespondentDownload">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'database']" />
        </div>
        <div class="md-step-title">
          Respondent <br />
          Download
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <!--      
      <div v-bind:class="[this.isActivePage(2) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToIncludeSection">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'puzzle-piece']" />
        </div>
        <div class="md-step-title">
          Include <br />
          Sections
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToDefaultFields">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'key']" />
        </div>
        <div class="md-step-title">
          Default <br />
          Fields
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
-->
      <div v-bind:class="[this.isActivePage(4) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToSurveyDesign">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'tasks']" />
        </div>
        <div class="md-step-title">
          Form <br />
          Design
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(5) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToSurveyPreview">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'eye']" />
        </div>
        <div class="md-step-title">
          Form <br />
          Preview
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(6) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToManageInvitations">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'envelope']" />
        </div>
        <div class="md-step-title">
          Manage <br />
          Forms
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import VueRouter from "vue-router";
import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";

export default {
  name: "StudyDesignMenu",
  components: {},
  mixins: [MenuMixins],
  router,
  props: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>
