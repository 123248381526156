<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
import $ from "jquery";

import axios from "axios";

export default {
  name: "FormList",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      title: "Form List",
    };
  },
  methods: {},
  created() {},
  mounted() {},
  computed: {},
};
</script>
