<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <study-design-menu></study-design-menu>
    <div v-if="isAdvancedUser">
      <div v-if="getDisplayView()" class="survey-design">
        <div class="row no-gutters">
          <div class="col text-right mb-3">
            <button type="submit" class="btn btn-primary" v-on:click="initializeSection">Add Section</button>
          </div>
        </div>
        <nav class="sectionActions"></nav>
        <div v-for="(section, idxSection) in this.sections" :key="'section' + idxSection">
          <survey-section
            v-if="section.active == 'Y'"
            :ref="idxSection"
            :section-object="section"
            :survey-id="survey.surveyId"
            :survey-name="survey.name"
            :survey-content-sections="surveyContentSections"
            :survey-template-object="survey.surveyTemplate"
            @reload="reloadSurveyContentSections"
          ></survey-section>
        </div>
        <survey-section
          v-if="isAddSectionMode"
          initial-mode="edit"
          :section-object="newSection"
          :survey-id="survey.surveyId"
          :survey-name="survey.name"
          :survey-content-sections="surveyContentSections"
          :survey-template-object="survey.surveyTemplate"
          @reload="reloadSurveyContentSections"
        ></survey-section>
        <div class="row no-gutters">
          <div class="col" role="group">
            <button type="submit" class="finish-button btn btn-primary float-right ml-2" v-on:click="goToSurveyPreview">Next</button>
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="goToIncludeSection">Previous</button>
          </div>
        </div>
        <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
          <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
        </div>
        <vue-modal name="navigate-to-default-fields" :width="350" :height="150">
          <div class="container">
            <div class="form-group form-row p-2">
              <h5 class="font-weight-bold">Redirect to Default Fields</h5>
            </div>
            <div class="form-group form-row align-items-center">You will be redirect to default fields to make modifications to this field</div>
            <div role="group" class="chart-buttons btn-group-horizontal">
              <button class="btn btn-primary" type="button" @click="goToDefaultFields">Go To Default Fields</button>
              <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
            </div>
          </div>
        </vue-modal>
      </div>
    </div>
    <div v-else class="include-sections text-center">You do not have permission to view this page</div>
  </div>
</template>
<script>
import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";
import SurveySection from "../../components/SurveySection.vue";
import ContentService from "../../services/content.service";
import SectionService from "../../services/section.service";
import AdminService from "../../services/admin.service";
import $ from "jquery";

export default {
  name: "FormDesign",
  mixins: [StudyDesignMixins, ContentMixins],
  components: {
    SurveySection,
  },
  props: {},
  data() {
    return {
      title: "Form Design",
      surveyContentSections: [],
      mode: "",
      newSection: {},
    };
  },
  methods: {
    initializeSection() {
      $("html, body").animate({ scrollTop: $(".finish-button").offset().top }, 500);
      SectionService.initializeSection(this.survey.surveyId, this.initializeSectionCallback, this);
    },
    initializeSectionCallback(response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.newSection = response.data.data;
        this.mode = "add-section";
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    closeModal() {
      this.$modal.hide("navigate-to-default-fields");
    },
    getSurveyPageContentCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyContentSections = response.data.data;
      }
    },
    reloadSurveyContentSections: function () {
      ContentService.getPageContent(this.survey.name, this.getSurveyPageContentCallBack, this);
    },
  },
  watch: {
    survey: function () {
      this.reloadSurveyContentSections();
      this.newSection.surveyId = this.survey.surveyId;
    },
  },
  created() {},
  mounted() {},
  computed: {
    isAddSectionMode: function () {
      return this.mode == "add-section";
    },
  },
};
</script>
