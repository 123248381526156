import $ from "jquery";
import jQuery from "jquery";
import { isConsole } from "mobile-device-detect";

const BASE_URL = process.env.VUE_APP_BASE_URL;
const BASE_ERROR_NOTIFICATION_OPTIONS = {
  group: "admin",
  type: "error",
  title: "Error",
  ignoreDuplicates: true,
  max: 1,
};

const BASE_SUCCESS_NOTIFICATION_OPTIONS = {
  group: "admin",
  type: "success",
  ignoreDuplicates: true,
  max: 1,
  duration: 2000,
};

const BASE_INFO_NOTIFICATION_OPTIONS = {
  group: "admin",
  type: "warn",
  ignoreDuplicates: true,
  max: 1,
  duration: 2000,
};

class AdminService {
  displayInfoMessage(message) {
    return $.extend(BASE_INFO_NOTIFICATION_OPTIONS, message);
  }
  displaySuccessMessage(message) {
    return $.extend(BASE_SUCCESS_NOTIFICATION_OPTIONS, message);
  }
  displayErrorMessage(message) {
    $.extend(BASE_ERROR_NOTIFICATION_OPTIONS, { duration: -1 });
    return $.extend(BASE_ERROR_NOTIFICATION_OPTIONS, message);
  }
  getBaseUrl() {
    return BASE_URL;
  }
  removeByName(list, name) {
    let removedItem = list.find((x) => x.name == name);
    if (typeof removedItem !== "undefined") {
      list.splice($.inArray(removedItem, list), 1);
    }
    return list;
  }
  getActiveOptions() {
    return [
      { name: "Active", value: "Y" },
      /*      { name: "Inactive", value: "N" }     */
    ];
  }
  getCurrencyOptions() {
    return [
      { name: "US Dollars", currency: "USD", localString: "en-US" },
      { name: "Argentine Peso", currency: "ARS", localString: "es-AR" },
      { name: "Australia", currency: "AUD", localString: "en-AU" },
      { name: "Brazilian Real", currency: "BRL", localString: "pt-BR" },
      { name: "British Pound", currency: "GBP", localString: "en-GB" },
      { name: "Canada", currency: "CAD", localString: "fr-CA" },
      { name: "China Yuan", currency: "CNY", localString: "zh-CN" },
      { name: "Euro", currency: "EUR", localString: "de-DE" },
      { name: "Indian Rupee", currency: "IDR", localString: "ta-IN" },
      { name: "Japanese Yen", currency: "JPY", localString: "ja-JP" },
      { name: "Russian Ruble", currency: "RUB", localString: "ru-RU" },
      { name: "Swiss Franc", currency: "CHF", localString: "de-CH" },
    ];
  }
  getFinancialFormatOptions() {
    return [
      { value: "Actuals", name: "Actuals" },
      { value: "Thousands", name: "In 1,000s (thousands)" },
      { value: "Millions", name: "In 1,000,000s (millions)" },
    ];
  }
  getCalculateGrowthRateOptions() {
    return [
      { value: "YoY", name: "Year-Over-Year (YOY)" },
      { value: "Sequentially", name: "Sequentially" },
      { value: "None", name: "None" },
    ];
  }
  getQuarterOptions() {
    return [1, 2, 3, 4];
  }
  getQuarterOptionsWithName() {
    return [
      { name: "Quarter 1", value: "1" },
      { name: "Quarter 2", value: "2" },
      { name: "Quarter 3", value: "3" },
      { name: "Quarter 4", value: "4" },
    ];
  }
  getCurrentTimePeriodName() {
    //"2020 - Q2"
    let currentDate = new Date();
    let currentTimePeriodName = currentDate.getFullYear() + " - Q";
    let currentMonth = currentDate.getMonth() + 1;
    if (currentMonth >= 1 && currentMonth < 4) {
      currentTimePeriodName += 1;
    } else if (currentMonth >= 4 && currentMonth < 7) {
      currentTimePeriodName += 2;
    } else if (currentMonth >= 7 && currentMonth < 10) {
      currentTimePeriodName += 3;
    } else {
      currentTimePeriodName += 4;
    }
    return currentTimePeriodName;
  }
  isOnScreen(elem) {
    // if the element doesn't exist, abort
    if (elem.length == 0) {
      return;
    }
    var $window = jQuery(window);
    var viewport_top = $window.scrollTop();
    var viewport_height = $window.height();
    var viewport_bottom = viewport_top + viewport_height;
    var $elem = jQuery(elem);
    var top = $elem.offset().top;
    var height = $elem.height();
    var bottom = top + height;

    return (
      (top >= viewport_top && top < viewport_bottom) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    );
  }
}

export default new AdminService();
