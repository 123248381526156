import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

const BASE_URL = AdminService.getBaseUrl();
const API_SURVEY_DESIGN_URL = BASE_URL + "/api/v1/survey-design";

class SectionService {
  async initializeSection(surveyId, callback, panelApp) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/initialize-section", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SectionService.initializeSection", error, "survey-designer", panelApp);
      });
  }
  async saveSection(sectionInfo, callback, panelApp) {
    const params = new URLSearchParams();
    params.append("sectionObjectString", sectionInfo);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-section", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SectionService.saveSection", error, "survey-designer", panelApp);
      });
  }
}
export default new SectionService();
