<template>
  <div v-if="displayBreadCrumb" class="main-breadcrumb-bar d-flex flex-row">
    <ol class="breadcrumb">
      <li v-for="(breadcrumb, idx) in breadcrumbList" :key="idx" @click="routeTo(idx)" :class="[!!breadcrumb.link ? linkedClass : normalClass]">{{ breadcrumb.name }}</li>
    </ol>
    <button class="navbar-toggler sidebar-toggler-button my-1 align-self-center" style="color: white; background-color: #2f5eb3" type="button" @click="$parent.toggleMainMenu()">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </button>
    <div class="pr-3 pl-1 pt-1">
      <i v-show="this.$root.getLoading()" class="fa fa-refresh fa-spin" @click="$parent.refreshPage"></i>
      <i v-show="!this.$root.getLoading()" class="fa no-spin" @click="$parent.refreshPage">&#xf021;</i>
    </div>
  </div>
</template>
<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      displayBreadCrumb: true,
      breadcrumbList: [],
      linkedClass: "breadcrumb-item linked",
      normalClass: "breadcrumb-item",
    };
  },
  destroyed() {},
  computed: {},
  mounted() {
    this.updateList();
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) {
        this.$router.push(this.breadcrumbList[pRouteTo].link);
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  props: {},
};
</script>
