<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container application-error-list">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <div v-else class="panel-admin-body npa-email-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";

export default {
  name: "ApplicationErrorList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "Application Error List",
      table: {
        id: "application-error-list-table",
        columns: [
          {
            title: "Application Error Id",
            data: "applicationErrorId",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "5rem",
          },
          { title: "Section", data: "applicationErrorType", name: "applicationErrorType", width: "5rem" },
          { title: "Location", data: "location", name: "location", width: "10rem" },
          { title: "Message", data: "errorMessage", name: "errorMessage", width: "25rem" },
          { title: "Client Name", data: "clientName", name: "clientName", width: "5rem" },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            width: "5rem",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY hh:mm:ss A");
            },
          },
        ],
      },
    };
  },
  methods: {
    async getTableData() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Loading table data please wait....." }));
      await axios
        .get(this.baseUrl + "/api/v1/admin/get-application-error-list", { headers: authHeader() })
        .then((response) => this.getTableDataCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ApplicationErrorList.getTableData", error, "admin", this);
        });
    },
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[5, "desc"]];
    }
  },
  props: {},
  mounted() {},
};
</script>
