<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <study-design-menu></study-design-menu>
    <div v-show="getDisplayView()" class="survey-preview container">
      <div v-if="!this.hasSurveyBeenBuilt" class="text-center text-danger">
        Your survey design changes have not been processed. Please return to the Default Fields page and click the Next button to generate the survey
      </div>
      <div class="text-center">Welcome to the FormSurvey Preview. This is how the form will display to the end user. The widgets are functional but the buttons are disabled.</div>
      <survey-taker :view-only="true" :entry-client-id="this.$parent.clientId.toString()" :entry-survey-url="this.survey.url" :entry-survey-code="this.surveyCode"></survey-taker>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";

import SurveyTaker from "../../interview-application/views/SurveyTaker.vue";
import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import AdminService from "../../services/admin.service";

export default {
  name: "FormPreview",
  mixins: [StudyDesignMixins],
  components: {
    SurveyTaker,
  },
  props: {},
  data() {
    return {
      title: "Form Preview",
    };
  },
  methods: {},
  created() {},
  mounted() {},
  computed: {},
};
</script>
