<template>
  <div v-if="disableFeature" class="form-row form-group">
    <label for="placeholder" class="col-1">Placeholder</label>
    <input v-model="placeholder" id="placeholder" name="placeholder" type="text" class="col-4" @blur="updatePlaceholder" />
  </div>
</template>
<script>
export default {
  name: "QuestionPlaceholder",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      placeholder: "",
      disableFeature: true,
    };
  },
  props: {
    initialPlaceholder: {
      type: String,
      default: "",
    },
  },
  methods: {
    updatePlaceholder: function () {
      this.$emit("save", this.placeholder);
    },
  },
  mounted() {
    this.placeholder = this.initialPlaceholder;
  },
  destroyed() {},
};
</script>
