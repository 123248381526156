<template>
  <div v-if="isAdmin || isUserEditMode" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <form accept-charset="UTF-8" enctype="multipart/form-data" autocomplete="off" class="form" action="#" method="post" novalidate="novalidate" id="UserFormId">
      <div v-if="getDisplayView()" class="add-user">
        <div class="pt-2 pb-2">
          <router-link :to="{ name: 'user-list' }">
            <button type="button" class="btn btn-success" id="ReturnToUserListButton">Return to User List</button>
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-row">
              <div v-if="isAdminEditMode" class="col-lg-3">
                <div class="form-group">
                  <label for="userId">User Id</label>
                  <div v-if="mode == 'edit-user'">{{ formUserObject.id }}</div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="w-100" for="active">Status</label>
                  <select id="active" v-show="!isUserEditMode" name="active" class="custom-select col-9 required" v-model="formUserObject.active">
                    <option value>Select</option>
                    <option v-for="(option, idxActiveOptions) in this.getActiveOptions()" v-bind:value="option.value" v-bind:key="idxActiveOptions">{{ option.name }}</option>
                  </select>
                  <div v-show="isUserEditMode">{{ statusName }}</div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="userType" class="w-100">User Type</label>
                  <select v-show="!isUserEditMode" id="userType" name="userType" class="custom-select required col-9" v-model="formUserObject.userTypeId">
                    <option value>Select</option>
                    <option v-for="(option, idxUserTypeOptions) in this.user_type_options" v-bind:value="option.userTypeId" v-bind:key="idxUserTypeOptions">
                      {{ option.userTypeName }}
                    </option>
                  </select>
                  <div v-show="isUserEditMode">{{ formUserObject.userTypeName }}</div>
                </div>
              </div>
              <div v-show="!isPowerUser" class="col-lg-3">
                <div class="form-group">
                  <label for="clientId">Client</label>
                  <treeselect
                    v-show="!isUserEditMode"
                    v-model="formUserObject.clientId"
                    :multiple="false"
                    :show-count="true"
                    :options="clientOptions"
                    :append-to-body="true"
                    :required="true"
                    :maxHeight="400"
                    :disabled="!isAdminUserType && !isAdminAddMode && !isAdminEditMode && !isAdminAddMode"
                    placeholder="Search..."
                  />
                  <div v-show="isUserEditMode">{{ clientName }}</div>
                </div>
              </div>
              <div v-show="isPowerUser" class="col-lg-3">
                <div class="form-group">
                  <label for="clientId">Client</label>
                  <treeselect
                    v-show="!isUserEditMode"
                    v-model="userClients"
                    :multiple="true"
                    :show-count="true"
                    :options="clientOptions"
                    :append-to-body="true"
                    :required="true"
                    :maxHeight="400"
                    :disabled="!isAdminUserType && !isAdminAddMode && !isAdminEditMode && !isAdminAddMode"
                    placeholder="Search..."
                  />
                  <div v-show="isUserEditMode">{{ clientName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="firstName" class="text-right">First Name</label>
                  <input v-model="formUserObject.firstName" id="firstName" name="firstName" type="text" class="form-control col-9 required" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="lastName" class="text-right">Last Name</label>
                  <input v-model="formUserObject.lastName" id="lastName" name="lastName" type="text" class="form-control col-9 required" />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="comp2">Email</label>
                  <input v-model="formUserObject.email" id="email" name="email" class="form-control col-9 required" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-row">
              <div class="col-lg-3">
                <label for="userName">User Name</label>
                <input v-model="formUserObject.userName" id="userName" name="userName" class="form-control col-9 required" type="text" />
              </div>
              <div v-if="isUserEditMode" class="col-lg-3">
                <label for="userName">Old Password</label>
                <input v-model="oldPassword" id="oldPassword" name="oldPassword" :class="[requirePassword ? requiredPasswordClass : basePasswordClass]" type="password" />
              </div>
              <div class="col-lg-3">
                <label for="userName">Your New Password</label>
                <input
                  v-model="newPassword"
                  id="newPassword"
                  name="newPassword"
                  :class="[requirePassword ? requiredPasswordClass : basePasswordClass]"
                  type="password"
                />
              </div>
              <div class="col-lg-3">
                <label for="userName">Retype Your New Password</label>
                <input
                  v-model="confirmPassword"
                  id="confirmPassword"
                  name="confirmPassword"
                  :class="[requirePassword ? requiredPasswordClass : basePasswordClass]"
                  type="password"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-12">
            <label for="address">Address</label>
            <textarea id="address" name="address" v-model="formUserObject.address" style="width:100%" class="form-control" rows="3"></textarea>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-12" role="group">
            <button type="submit" class="btn btn-primary float-right ml-2" @click="saveUser()">
              <span>{{ buttonName }}</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>          
            </button>
          </div>
        </div>
      </div>
    </form>
	</div>
  <div v-else class="panel-admin-body user-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

require("jquery-validation");
import AdminMixins from "../../mixins/AdminMixins.vue";
import UserService from "../../services/user.service";
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";
import AdminService from "../../services/admin.service";
import ErrorService from "../../services/error.service";
import ValidatorService from "../../services/validator.service";

export default {
  name: "AddUser",
  mixins: [AdminMixins],
  components: {},
  data() {
    return {
      mode: "",
      userClients: [],
      userForm: null,
      validator: null,
      basePasswordClass: "form-control col-9",
      requiredPasswordClass: "form-control col-9 required passwordRequired",
      title: "Add New User",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      formUserObject: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
      },
      user_type_options: [],
      clientOptions: [],
      summernoteConfiguration: {
        direction: "ltr",
        height: 100,
        tabsize: 2,
        dialogsInBody: true,
        toolbar: [
          ["style", ["fontname", "fontsize", "color", "bold", "italic", "underline", "strikethrough", "clear"]],
          ["Paragraph style", ["style", "ol", "ul", "paragraph", "height"]],
          ["Misc", ["fullscreen", "codeview", "undo", "redo", "help"]],
          ["mybutton", ["hello"]],
        ],
      },
    };
  },
  methods: {
    onSelect(option) {
      let index = this.clientOptions.findIndex((item) => item.label == option.label);
      this.clientOptions[index].checked = true;
    },
    onRemove(option) {
      let index = this.clientOptions.findIndex((item) => item.label == option.label);
      this.clientOptions[index].checked = false;
    },
    getActiveOptions() {
      return AdminService.getActiveOptions();
    },
    async getUser() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({text: "Retrieving user please stand by......"}));
      let userId = (this.initialUserId == 0 && this.isAdminAddMode) || this.isUserEditMode ? UserService.getUser().id : this.initialUserId;
      UserService.getUserFromServer(userId, this.getUserCallback, this);
    },
    getUserCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });

      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.formUserObject = response.data.data;
        this.oldPassword = this.formUserObject.password;
        if (this.isAdminDuplicateMode){
          this.formUserObject.id = 0;
          this.formUserObject.userName = this.formUserObject.userName + "1"; 
        }
        this.setUserClients();
        this.setDisplayView(true);
        let vm = this;
        $(document).ready(function() {
          $("#address").summernote(vm.summernoteConfiguration);
          $("#address").summernote("code", vm.formUserObject.address);
        });
      } else {
        this.$notify(AdminService.displayErrorMessage({text: response.data.message}));
      }
    },
    setUserClients() {
      const vm = this;
      $.each(this.formUserObject.userClients, function (i, item) {
        let inputCompetitor = item;
        vm.userClients[i] = inputCompetitor.id;
      });
    },
    async saveUser() {
      if (!this.userForm.valid()) {
        return false;
      } else {
      this.$root.setLoading(true);
        this.$notify(AdminService.displayInfoMessage({text: "Saving user please stand by......"}));
        if (this.newPassword != "") {
          this.formUserObject.password = this.newPassword;
        } else {
          delete this.formUserObject["password"];
        }
        UserService.saveUser(this.mode, this.formUserObject, this.userClients, this.saveUserCallback, this);
      }
    },
    saveUserCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });

      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({text: response.data.message}));
        this.formUserObject = response.data.data;
        this.oldPassword = this.formUserObject.password;
        if (this.mode == "add" || this.mode == "duplicate-user") {
          this.mode = "edit-user";
        } else if (this.mode == "user-settings"){
          UserService.saveUserSettings(this.formUserObject);
        }
      } else {
        this.$notify(AdminService.displayErrorMessage({text: response.data.message}));
      }
    },
    loadUserTypesCallback(response) {
      this.user_type_options = response.data;
    },
    async loadUserTypes() {
      const params = new URLSearchParams();
      await axios
        .get(this.baseUrl + "/api/user/get-all-user-types", { headers: authHeader() })
        .then((response) => this.loadUserTypesCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("AddUser.loadUserTypes", error, "admin", this);
        });
    },
    async loadData() {
      this.loadUserTypes();
	  ClientService.getAllClients(this.loadClientDataCallback);
    },
    loadClientDataCallback(data) {
      this.clientOptions = data;
    },
    setupUserForm() {
      this.userForm = $("#UserFormId");
      jQuery.validator.setDefaults({
        debug: true,
        success: "valid",
      });
      jQuery.validator.addMethod(
        "notEqual",
        function(value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );
      jQuery.validator.addClassRules("passwordRequired", { required: true, minlength: 8 });
      jQuery.validator.addMethod("passwordCheck", ValidatorService.passwordCheck, ValidatorService.passwordErrorMessage);
      this.validator = this.userForm.validate({
        rules: {
          clientId: {
            required: true,
            notEqual: "0",
          },
          confirmPassword: {
            equalTo: "#newPassword",
          },
        },
        highlight: function(element) {
          $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
        },
        unhighlight: function(element) {
          $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function(error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        },
      });
    },
  },
  computed: {
    buttonName: function() {
      if (this.mode == "edit-user" || this.mode == "user-settings"|| this.mode == "duplicate-user") {
        return "Save User";
      } else {
        return "Add User";
      }
    },
    statusName: function() {
      if (this.formUserObject) {
        return this.formUserObject.active == "Y" ? "Active" : "Inactive";
      } else {
        return "";
      }
    },
    isPowerUser: function () {
      return this.formUserObject.userTypeId == 4;
    },
    isAdminAddMode: function() {
      return this.mode == "add-user";
    },
    isAdminEditMode: function() {
      return this.mode == "edit-user";
    },
    isAdminDuplicateMode: function() {
      return this.mode == "duplicate-user";
    },
    isUserEditMode: function() {
      return this.mode == "user-settings" || this.mode == "";
    },
    isAdminUserType: function() {
      return this.formUserObject.userTypeId == "1";
    },
    requirePassword: function() {
      return this.newPassword != "" || this.isAdminDuplicateMode || this.isAdminAddMode;
    },
  },
  watch: {
    displayView: function() {
      this.setupUserForm();
    },
  },
  async created() {
    this.mode = this.$route.name;
    if (this.mode == "edit-user" || this.mode == "add-user"  || this.initialMode == "duplicate-user") {
      if (this.initialMode == "duplicate-user"){
        this.mode == this.initialMode;
      }
      await this.loadData();
      if (this.mode == "edit-user") {
        this.title = "Modify User";
        this.getUser();
      } else if (this.mode == "duplicate-user") {
        this.title = "Copy User";
        this.getUser();
      } else {
        this.title = "Add User";
        this.setDisplayView(true);
        let vm = this;
        $(document).ready(function() {
          $("#address").summernote(vm.summernoteConfiguration);
        });
      }
    } else {
      this.title = "User";
      this.mode = "user-settings";
      this.getUser();
    }
  },
  props: {
    initialMode: {
      type: String,
      default: "",
    },
    initialUserId: {
      type: Number,
      default: 0,
    },
  },
  mounted() {},
};
</script>
