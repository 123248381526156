import axios from "axios";
import authHeader from "./auth-header";
import jQuery from "jquery";
import ErrorService from "./error.service";
import AdminService from "./admin.service";

const API_USER_URL = AdminService.getBaseUrl() + "/api/user";

class UserService {
  saveUserSettings(user) {
    let saveUser = this.getUser();
    saveUser.user = user;

    localStorage.setItem("user", JSON.stringify(saveUser));
  }

  getUser() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user"));
    }
  }

  getUserId() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).id;
    }
  }
  async deleteUser(deleteUserId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("deleteUserId", deleteUserId);
    params.append("userId", this.getUserId());
    await axios
      .post(API_USER_URL + "/delete-user", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.deleteSurvey", error, "admin", chartApp);
      });
  }
  getUserTypeId() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).user.userTypeId;
    }
  }
  async saveUser(mode, formUserObject, userClients, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("mode", mode);
    params.append("userObject", JSON.stringify(formUserObject));
    params.append("adminUserId", this.getUserId());
    params.append("userClients", JSON.stringify(userClients));
    await axios
      .post(API_USER_URL + "/save-user", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.saveUser", error, "admin", chartApp);
      });
  }
  async getUserFromServer(userId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    params.append("adminUserId", this.getUserId());
    await axios
      .post(API_USER_URL + "/get-user", params, { headers: authHeader() })
      .then((response) => callback(response))
      .catch((error) => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.getUser", error, "admin", chartApp);
      });
  }
}

export default new UserService();
