<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";
import AdminService from "../services/admin.service";

export default {
  name: "AdminMixins",
  mixins: [ContentMixins, ViewMixins],
  components: {},
  data() {
    return {
      modalReturnMessage: "",
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl(),
    },
  },
  created() {},
  methods: {
    displayDeleteConfirmation(modalId) {
      this.$modal.show(modalId);
    },
  },
  watch: {},
  mounted() {
    this.$parent.displayShowMenuIcon = false;
  },
  computed: {
    clientId: function () {
      if (typeof this.$parent.$data.clientId != "undefined") {
        return this.$parent.$data.clientId;
      } else {
        return 0;
      }
    },
    clientName: function () {
      if (typeof this.$parent.clientObject.name != "undefined") {
        return this.$parent.clientObject.name;
      } else {
        return "";
      }
    },
    isAdmin: function () {
      return this.$parent.isAdmin;
    },
  },
};
</script>
