<template>
  <vue-modal :name="name" :width="width" :height="height">
    <forgot-password />
  </vue-modal>
</template>
<script>
import ModalMixins from "../mixins/ModalMixins.vue";
import ForgotPassword from "../components/ForgotPassword.vue";

export default {
  name: "ForgotPasswordModal",
  mixins: [ModalMixins],
  components: {
    ForgotPassword,
  },
  methods: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.title = "Forgot Password";
    this.name = "forgot-password";
  },
  watch: {},
  computed: {},
};
</script>
