<template>
<vue-modal :name="modalId" :scrollable="true" :height="height" :width="width">
      <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" v-html="modalContent"></div>
      <div class="modal-footer">
        <button v-if="includeConfirmation" class="btn btn-primary" type="button" @click="confirmCallback">Confirm</button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "Modal",
  data() {
    return {};
  },
  destroyed() {},
  computed: {},
  mounted() {},
  methods: {
    closeModal() {
      this.$modal.hide(this.modalId);
    },
    confirmCallback() {
      this.$emit("confirm", this.modalResultId);
      this.$modal.hide(this.modalId);
    },
  },
  watch: {},
  props: {
    modalContent: {
      type: String,
      default: "",
    },
    modalTitle: {
      type: String,
      default: "",
    },
    modalId: {
      type: String,
      default: "",
    },
    modalResultId: {
      type: Number,
      default: 0,
    },
    includeConfirmation: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: isMobile ? 220 : 368,
    },
    height: {
      type: String,
      default: "auto",
    },
  },
};
</script>
