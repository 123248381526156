<template>
  <div class="w-100">
    <div id="survey-taker-app">
      <notifications group="admin" position="top center" />
      <h4 class="text-center m-4">
        <span>{{ surveyName }}</span>
      </h4>
      <div class="container">
        <div v-show="!displaySurvey && !displayCompletedSurveyMessage" class="text-center m-3 info-message">
          <span class="error-message" v-if="error">{{ message }}</span>
          <span v-if="!error">{{ message }}</span>
        </div>

        <div v-show="displayCompletedSurveyMessage" class="completed-survey-message">
          <div v-if="isContentEmpty">
			You have successfully completed this online form. Thank you for your participation.
		  </div>
          <div v-if="!isContentEmpty">
            <p v-html="this.thankYouMessageContent"></p>
          </div>
        </div>
        <div v-show="displaySurvey" class="card-body">
          <form enctype="multipart/form-data" autocomplete="off" class="form surveyPreviewForm" @submit.prevent="surveySubmitAction" method="post" id="SurveyPreviewFormId">
            <div class="section" v-for="(section, idxSection) in this.sections" v-bind:key="idxSection">
              <survey-taker-section
                :base-url="baseUrl"
                :section-object="section"
                :survey-taker-id="surveyTakerId"
                :survey-taker-ip-address="surveyTakerIpAddress"
                :survey-id="surveyId"
                :disable-widgets="viewOnly"
              ></survey-taker-section>
            </div>
          </form>
          <div v-show="!readOnly" class="survey-buttons btn-group-horizontal" role="group">
            <button type="submit" class="btn btn-primary mr-2" v-on:click="submitForm" :disabled="viewOnly">Submit</button>
          </div>
          <div class="footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import SurveyTakerSection from "../components/SurveyTakerSection.vue";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import ContentMixins from "../../mixins/ContentMixins.vue";

import axios from "axios";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import "../../sass/_admin.scss";

require("jquery-validation");
// Configuration VueAnalytics
if (process.env.VUE_APP_BASE_IS_INTERVIEW_APP) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_ANALYTICS,
    debug: {
      enabled: false,
    },
  });
}

export default {
  name: "SurveyTaker",
  components: {
    SurveyTakerSection,
  },
  mixins: [ContentMixins],
  data() {
    return {
      survey: {},
      title: "Preview",
      surveyPreviewForm: null,
      validator: null,
      surveyTakerId: "",
      surveyStarted: false,
      surveyTakerIpAddress: "",
      surveyTakerLocation: {},
      surveyResults: [],
      displaySurvey: false,
      displayCompletedSurveyMessage: false,
      message: "",
      pageNumber: 5,
      uniqueCodeResponse: {
        message: "This invitation code is invalid.  Please click on your survey link, or copy/paste the full link into your browser address field. ",
        verified: true,
      },
      statusResponse: {
        message: "This online interview is now closed. ",
        verified: true,
      },
      error: false,
      thankYouMessageContentTitle: "Thank You Message",
      thankYouMessageContent: "",
    };
  },
  methods: {
    async loadContent() {
      let contentSection = this.pageContent.find((x) => x.title == this.thankYouMessageContentTitle);
      if (typeof contentSection != "undefined") {
        this.thankYouMessageContent = contentSection.contentText;
      } else {
        this.thankYouMessageContent = "";
      }
    },
    getSurveyField(surveyFieldName) {
      if (this.surveyFields) {
        return this.surveyFields.find((x) => x.surveyFieldName == surveyFieldName);
      } else {
        return {};
      }
    },
    setSurveyStarted() {
      if (!this.surveyStarted) {
        this.$ga.event(this.surveyName, "survey started", "", 0);
        this.surveyStarted = true;
        this.saveSurveySummary("I");
      }
    },
    async submitForm() {
      if (!this.surveyPreviewForm.valid()) {
        this.$notify(AdminService.displayErrorMessage({ text: "Please complete the required fields before submitting the survey" }));
        return false;
      } else {
        this.$notify(AdminService.displayInfoMessage({ text: "Submitting form please wait....." }));

        const params = new URLSearchParams();
        params.append("surveyId", this.surveyId);
        params.append("surveyTakerId", this.surveyTakerId);
        params.append("surveyTakerIpAddress", this.surveyTakerIpAddress);
        params.append("surveyTakerLocation", JSON.stringify(this.surveyTakerLocation));
        params.append("surveyResults", JSON.stringify(this.surveyResults));
        await axios
          .post(this.baseUrl + "/api/v1/interview/submit-survey", params)
          .then((response) => this.submitFormCallback(response))
          .catch((error) => {
            error.message += ErrorService.mergeError(error);
            error.message += this.surveyTakerMessage;
            ErrorService.processError("SurveyTaker.submitForm", error, "survey-taker", this);
            this.displayCompletedSurveyMessage = true;
            return false;
          });
        this.displaySurvey = false;
        this.displayCompletedSurveyMessage = true;
      }
    },
    processSurveyCallback() {
      this.$notify({ group: "admin", clean: true });
      this.$ga.event(this.surveyName, "survey processed", "", 0);
      $("html, body").animate({ scrollTop: $(".completed-survey-message").offset().top }, 500);
      this.processIpsosRedirect();
    },
    async submitFormCallback(response) {
      this.$ga.event(this.surveyName, "survey submitted to wData", "", 0);

      this.displaySurvey = false;
      this.$notify(AdminService.displayInfoMessage({ text: "Survey still processing....Do not close the browser." }));
      const params = new URLSearchParams();
      params.append("surveyUrl", this.entrySurveyUrl);
      params.append("surveyTakerId", this.surveyTakerId);
      params.append("uniqueCode", this.uniqueCode);
      params.append("clientId", this.entryClientId);

      await axios
        .post(this.baseUrl + "/api/v1/interview/process-survey", params)
        .then((response) => this.processSurveyCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error);
          error.message += this.surveyTakerMessage;
          ErrorService.processError("SurveyTaker.submitFormCallback", error, "survey-taker", this);
        });
    },
    setupSurveyCallback(response) {
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data.survey;
        if (!jQuery.isEmptyObject(response.data.data.uniqueCodeResponse)) {
          this.uniqueCodeResponse = response.data.data.uniqueCodeResponse;
        }
        if (!jQuery.isEmptyObject(response.data.data.statusResponse)) {
          this.statusResponse = response.data.data.statusResponse;
        }

        if (jQuery.isEmptyObject(response.data.data.survey)) {
          this.$notify(AdminService.displayErrorMessage({ text: "Unable to load the survey with the following survey code: " + this.entrySurveyCode }));
          let response ={
            message: "Unable to load the survey with the following survey code: " + this.entrySurveyCode
          }
          ErrorService.processError("SurveyTaker.setupSurveyCallback", response, "survey-taker", this);
          this.displaySurvey = false;
        } else {
          this.displaySurvey = true;
          this.initializeSurvey();
          this.setupSurveyPreviewForm();
        }
        this.getPageContentByPage(this.surveyName);
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
      if (!this.uniqueCodeResponse.verified && !this.viewOnly) {
        this.$notify(AdminService.displayErrorMessage({ text: this.uniqueCodeResponse.message, duration: -1 }));
        this.displaySurvey = false;
      }
      if (!this.statusResponse.verified && !this.viewOnly) {
        this.$notify(AdminService.displayErrorMessage({ text: this.statusResponse.message, duration: -1 }));
        this.displaySurvey = false;
      }
    },
    async saveSurveySummary(status) {
      const params = new URLSearchParams();
      params.append("surveyId", this.surveyId);
      params.append("surveyTakerId", this.surveyTakerId);
      params.append("surveyTakerIpAddress", this.surveyTakerIpAddress);
      params.append("surveyTakerLocation", JSON.stringify(this.surveyTakerLocation));
      params.append("status", status);
      await axios
        .post(this.baseUrl + "/api/v1/interview/save-survey-summary", params)
        .then((response) => this.$log.info("Survey Initialized"))
        .catch((error) => {
          error.message += ErrorService.mergeError(error);
          error.message += this.surveyTakerMessage;
          ErrorService.processError("SurveyTaker.saveSurveySummary", error, "survey-designer", this);
        });
    },
    async initializeSurvey() {
      if (!this.viewOnly) {
        this.surveyTakerId = Math.random().toString(36).replace("0.", "");
        this.$ga.set("userId", this.surveyTakerId);
        this.$ga.event(this.surveyName, "survey viewed", "", 0);
        const vm = this;
        try {
          await $.get(
            "https://api.ipdata.co?api-key=bfd8320202519ed6762f86b313112330e7bd72e8f47b38b3a0218109",
            function (response) {
              vm.surveyTakerIpAddress = response.ip;
              vm.surveyTakerLocation.countryCode = response.country_code;
              vm.surveyTakerLocation.regionCode = response.region_code;
              vm.surveyTakerLocation.regionCode = response.region;
              vm.surveyTakerLocation.city = response.city;
              vm.saveSurveySummary("O");
            },
            "jsonp"
          );
        } catch (err) {
          this.$log.info("Error getting users ip Address with the error: " + err.message);
          this.saveSurveySummary("O");
        }
      }
    },
    async setupSurvey() {
      this.$notify(AdminService.displayInfoMessage({ text: "Please wait while the " + this.type + " loads......." }));
      const params = new URLSearchParams();
      params.append("uniqueCode", this.uniqueCode);
      params.append("clientId", this.entryClientId);
      if (this.entrySurveyUrl != "") {
        params.append("surveyUrl", this.entrySurveyUrl);
        await axios
          .post(this.baseUrl + "/api/v1/interview/get-survey-by-survey", params)
          .then((response) => this.setupSurveyCallback(response))
          .catch((error) => {
            this.$notify({ group: "admin", clean: true });
            error.message += ErrorService.mergeError(error);
            error.message += this.surveyTakerMessage;
            ErrorService.processError("SurveyTaker.setupSurvey", error, "survey-taker", this);
          });
      } else if (this.entrySurveyCode != "") {
        params.append("surveyCode", this.entrySurveyCode);
        await axios
          .post(this.baseUrl + "/api/v1/interview/get-survey", params)
          .then((response) => this.setupSurveyCallback(response))
          .catch((error) => {
            error.message += ErrorService.mergeError(error);
            error.message += this.surveyTakerMessage;
            ErrorService.processError("SurveyTaker.setupSurvey", error, "survey-taker", this);
          });
      }
    },
    setupSurveyPreviewForm() {
      this.surveyPreviewForm = $("#SurveyPreviewFormId");
      this.validator = this.surveyPreviewForm.validate(ErrorService.setupValidationForm());
      $(document).ready(function () {
        $('input[type=tel][name^="question_"]').each(function (i, el) {
          $(this).rules("add", {
            phoneUS: true,
          });
        });
        $('input[type=radio][name^="question_"].required').each(function (i, el) {
          if (typeof el.rules != "undefined"){
	          el.rules("add", {
	            notSure: true,
	          });
          }
        });
        $('select[name^="question_"].required').each(function (i, el) {
          if (typeof el.rules != "undefined"){
	          el.rules("add", {
	            selectNone: true,
	          });
          }
        });
      });
    },
    goToRespondentDownload: function () {
      this.$router.replace({ path: "/study-design/respondent-download" });
    },
    goToIncludeSection: function () {
      this.$router.replace({ path: "/study-design/include-sections" });
    },
    goToSurveyPreview: function () {
      this.$router.replace({ path: "/form-design/form-preview" });
    },
    goToDefaultFields: function () {
      this.$router.replace({ path: "/study-design/default-fields" });
    },
    goToSurveyDesign: function () {
      this.$router.replace({ path: "/form-design/form-design" });
    },
    goToManageInvitations: function () {
      this.$router.replace({ path: "/study-design/manage-invitations" });
    },
    processIpsosRedirect: function(){
      if (localStorage.getItem("id") != null ) {
        let requestString = "?PID=S21007636&stat=c&id="
        requestString += localStorage.getItem("id");
        this.$ga.event(this.surveyName, "Ipsos Redirect", requestString, 0);
        localStorage.removeItem("PID");
        localStorage.removeItem("id");

        window.location.href = "https://surveys.ipsosinteractive.com/surveys/"+requestString;
      }
    },
  },
  computed: {
    isContentEmpty: function () {
      return this.thankYouMessageContent == "";
    },
    surveyTakerMessage: function () {
      return "\n" + this.type + " taker id: " + this.surveyTakerId;
    },
    isSurveyEmpty: function () {
      return jQuery.isEmptyObject(this.survey);
    },
    sections: function () {
      if (this.isSurveyEmpty) {
        return {};
      }
      return this.survey.sections;
    },
    surveyName: function () {
      if (this.isSurveyEmpty) {
        return "";
      }
      return this.survey.name;
    },
    surveyId: function () {
      if (this.isSurveyEmpty) {
        return "";
      }
      return this.survey.surveyId;
    },
    surveySubmitAction: function () {
      return this.baseUrl + "/api/v1/survey/submit-survey";
    },
    surveyFields: function () {
      if (this.survey.surveyFields) {
        return this.survey.surveyFields;
      } else {
        return [];
      }
    },
  },
  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_BASE_URL,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "survey",
    },
    uniqueCode: {
      type: String,
      default: "",
    },
    entrySurveyUrl: {
      type: String,
      default: "",
    },
    entrySurveyCode: {
      type: String,
      default: "",
    },
    entryClientId: {
      type: String,
      default: "0",
    },
    entryResponses: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  created() {},
  watch: {
    entrySurveyCode: function () {
      this.setupSurvey();
    },
    pageContent: function () {
      this.loadContent();
    },
  },
  mounted() {
    this.setupSurvey();
    var currentUrl = window.location.pathname;
    this.$ga.page(currentUrl);
    this.$log.info("Starting " + process.env.VUE_APP_TITLE);
  },
};
</script>
