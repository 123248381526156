<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container content-list">
      <div class="row no-gutters">
        <div class="pr-2">
          <router-link :to="{ name: 'edit-content', params: { initialMode: 'add' } }">
            <button type="button" class="btn btn-primary" id="AddNewContentButton">
              Add Content
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'plus-circle']" />
              </span>
            </button>
          </router-link>
        </div>
        <div class="pr-2">
          <router-link :to="{ name: 'edit-content', params: { initialMode: 'edit', initialContentId: this.table.rowSelectedId } }">
            <button type="button" class="btn btn-primary" id="EditContentButton" :disabled="!table.rowSelected">
              Edit Content
              <span class="ml-auto">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
            </button>
          </router-link>
        </div>
        <div class="pr-2">
          <button type="button" class="btn btn-primary" id="PreviewContentButton" :disabled="!table.rowSelected" @click="getContent('Preview')">
            Preview Content
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'eye']" />
            </span>
          </button>
        </div>
        <div class="pr-2">
          <button type="button" class="btn btn-primary" id="DeleteContentButton" :disabled="!table.rowSelected" @click="displayDeleteConfirmation(deleteConfirmationTitle)">
            Delete Content
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'ban']" />
            </span>
          </button>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-sm-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead></thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
    <modal :modal-id="'Preview'" :modal-title="'Preview'" :modal-content="previewModalContent" :modal-result-id="table.rowSelectedId" />

    <modal
      :modal-id="deleteConfirmationTitle"
      :modal-title="'Delete Confirmation'"
      :modal-content="'Are you sure you want delete this ?'"
      :modal-result-id="table.rowSelectedId"
      :include-confirmation="true"
      @confirm="deleteContent"
    />
  </div>
  <div v-else class="panel-admin-body content-email-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";
import AdminService from "../../services/admin.service";
import ErrorService from "../../services/error.service";
import UserService from "../../services/user.service";
import ContentService from "../../services/content.service";

export default {
  name: "ContentList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "Content List",
      previewModalContent: "",
      table: {
        id: "content-list-table",
        columns: [
          {
            title: "Content Id",
            data: "id",
            createdCell: function (td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
          },
          { title: "Page", data: "page", name: "page" },
          { title: "Title", data: "title", name: "title" },
          { title: "Summary", data: "summaryText", name: "summaryText" },
          {
            title: "Active",
            data: "active",
            className: "text-center",
            name: "status",
            render: function (data, type, row) {
              var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
              if (type === "display") {
                return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
              } else {
                return data;
              }
            },
          },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
            },
          },
          {
            title: "Date Updated",
            data: "dateModified",
            type: "date",
            name: "dateModified",
            render: function (data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY");
            },
          },
        ],
      },
      modalConfiguration: {
        title: "Delete Confirmation",
        contentText: "Are you sure you want delete this? ",
      },
    };
  },
  methods: {
    async getContent(title) {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Retrieving content please stand by....." }));
      ContentService.getContentById(title, this.table.rowSelectedId, this.getContentCallback, this);
    },
    getContentCallback(response, title) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.previewModalContent = response.data.data.contentText;
        this.$modal.show(title);
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },

    deleteContentCallback(response) {
      this.$root.setLoading(false);
      this.$notify({ group: "admin", clean: true });
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        this.getTableData();
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
    },
    async deleteContent(result) {
      this.$root.setLoading(true);
      let contentId = result;
      this.$notify(AdminService.displayInfoMessage({ text: "Removing content section....." }));
      const params = new URLSearchParams();
      params.append("contentId", contentId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(this.baseUrl + "/api/v1/content/delete-content", params, { headers: authHeader() })
        .then((response) => this.deleteContentCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContentList.deleteContent", error, "admin", this);
        });
    },
    async getTableData() {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Loading table data please wait....." }));
      await axios
        .get(this.baseUrl + "/api/v1/content/get-all-content", { headers: authHeader() })
        .then((response) => this.getTableDataCallback(response))
        .catch((error) => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ContentList.getTableData", error, "admin", this);
        });
    },
  },
  computed: {
    deleteConfirmationTitle: function () {
      return "Confirmation " + this.table.rowSelectedId;
    },
  },
  created() {
    if (this.isAdmin) {
      this.table.configuration.pageLength = 50;
      this.table.configuration.buttons = [];
      this.getTableData();
    }
  },
  props: {},
  mounted() {},
};
</script>
